import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxPopupModule } from 'devextreme-angular/ui/popup';


import { AuthService, AppInfoService } from '../../services';
import { AppSettings } from '../../../app-settings';

import { AngularMaterial } from 'src/app/Material/Angular/angularMaterial.module';
import { EncryptionDecryptionService } from '../../services/encryption-decryption.service';
import { UtilitiesService } from '../../services/utilities.service';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  userLoginForm: FormGroup;
  message = '';
  code: number;
  isLoggedIn: any ;
  response: any;
  disableBtn = false;
  appName = AppSettings.appNme;
  changeTemporaryPasswordForm: FormGroup;
  hideChangeTemporaryPasswordForm = true;
  firstLoginUser = '';
  constructor(private authService: AuthService,
              private utilities: UtilitiesService,
              public appInfo: AppInfoService,
              private router: Router, private spinner: SpinnerVisibilityService,
              public toastr: ToastrService,
              private encryptionService: EncryptionDecryptionService) {
      this.userLoginForm = new FormGroup({
      requestType: new FormControl('USER_LOGIN', Validators.compose([])),
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
      this.changeTemporaryPasswordForm = new FormGroup({
      requestType: new FormControl('SP_USER_PASSWORD_CHANGE', Validators.compose([])),
      userId: new FormControl(null, Validators.compose([])),
      oldPassword: new FormControl(null, Validators.compose([Validators.required])),
      newPassword: new FormControl(null, Validators.compose([Validators.required])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required]))
    });
      // sets the current page title in index.html title tag
      this.appInfo.setTitle('Login');

   }

// ==========================================================================================
// CHANGES

onLoginClick() {
  if (!this.userLoginForm.valid) {
    this.toastr.error('Fill all the fields to proceed', 'Credentials required!');
    return;
  }
  this.disableBtn = true;
  this.spinner.show();
  this.userLoginForm.get('requestType').patchValue('USER_LOGIN');
  this.authService.logIn(this.userLoginForm.value.username, this.userLoginForm.value.password)
    .subscribe(
        response => {
        const authResponce = response.json();
        
        this.message = authResponce.statusMessage;
        this.code = authResponce.statusId;
        if (this.code == 2000) {
          this.toastr.success(this.message, 'Success!');
          this.disableBtn = false;
          // reset all and then set
          sessionStorage.removeItem(AppSettings.sessionKey);
          sessionStorage.removeItem(AppSettings.isLoggedInKey);
          sessionStorage.clear();
          //spId
          console.log(authResponce.data);
          sessionStorage.setItem(AppSettings.isLoggedInKey, this.encryptionService.encryptString(authResponce.statusId));
          sessionStorage.setItem(AppSettings.userDataKey, this.encryptionService.encryptString(authResponce.data.user));
          sessionStorage.setItem(AppSettings.sessionKey, this.encryptionService.encryptString(authResponce.data.dolor));
          sessionStorage.setItem('userId', authResponce.data.user.userId);
          sessionStorage.setItem('spId', authResponce.data.user.spId);
          //location.reload();
          this.router.navigate(['/home']);
          
          this.spinner.hide();
        } else if (this.code == 2013) {
          this.disableBtn = false;
          sessionStorage.removeItem(AppSettings.sessionKey);
          sessionStorage.removeItem(AppSettings.isLoggedInKey);
          sessionStorage.removeItem(AppSettings.userDataKey);
          sessionStorage.clear();
          this.userLoginForm.reset();

          this.firstLoginUser = authResponce.data.user.firstName;
          this.hideChangeTemporaryPasswordForm = false;
          this.changeTemporaryPasswordForm.get('userId').patchValue(authResponce.data.user.userId);

          sessionStorage.setItem(AppSettings.isLoggedInKey, this.encryptionService.encryptString(authResponce.statusId));

          sessionStorage.setItem(AppSettings.userDataKey, this.encryptionService.encryptString(authResponce.data.user));

          sessionStorage.setItem(AppSettings.sessionKey, this.encryptionService.encryptString(authResponce.data.dolor));

          // this.router.navigate(['/change-temporary-password']);
          this.toastr.info(this.message, 'Change temporary password!');
          this.spinner.hide();
        } else {
          this.disableBtn = false;
          sessionStorage.setItem(AppSettings.isLoggedInKey, this.encryptionService.encryptString(authResponce.statusId));
          this.toastr.error(this.message, 'Alert!');
        }
        this.spinner.hide();
      },
      error => {
        this.disableBtn = false;
        this.toastr.error(`Couldn\'t connect to API: ${AppSettings.phpInterceptor} \n` + error, 'Communication Error');
        this.spinner.hide();
      });
}


onChangeTemporaryPassword() {
  this.disableBtn = true;
  if (this.changeTemporaryPasswordForm.invalid) {
    this.toastr.error(`Dear ${this.firstLoginUser} you're required to fill the the details to proceed`, 'Missing Information');
    return;
  }

  const password = `${this.changeTemporaryPasswordForm.get('newPassword').value}`;

  if (password.length < 8) {
    this.toastr.error(`Dear ${this.firstLoginUser} Your password must be at least 8 characters`, 'Password Complexity');
    return;
 }
if (password.search(/[a-z]/i) < 0) {
    this.toastr.error(`Dear ${this.firstLoginUser} Your password must contain at least one lowercase letter.`, 'Password Complexity');
    return;
}
if (password.search(/[A-Z]/i) < 0) {
    this.toastr.error(`Dear ${this.firstLoginUser} Your password must contain at least one uppcase letter.`, 'Password Complexity');
    return;
}
if (password.search(/[0-9]/) < 0) {
    this.toastr.error(`Dear ${this.firstLoginUser} Your password must contain at least one digit.`, 'Password Complexity');
    return;
}
if (password.search(/[-?!@#$%^&*\/\\]/) < 0) {
    this.toastr.error(`Dear ${this.firstLoginUser} Your password must contain at least one special character.`, 'Password Complexity');
    return;
}

  if (!`${this.changeTemporaryPasswordForm.get('newPassword').value}`.match(`${this.changeTemporaryPasswordForm.get('confirmPassword').value}`)) {
    this.toastr.error(`Dear ${this.firstLoginUser} your new password does not match, confirm password`, 'Password Mismatch');
    return;
  }

  if (`${this.changeTemporaryPasswordForm.get('oldPassword').value}`.match(`${this.changeTemporaryPasswordForm.get('newPassword').value}`)) {
    this.toastr.error(`Dear ${this.firstLoginUser} your new password should not match, old password`, 'Alert');
    return;
  }

  this.spinner.show();
  this.utilities.postServiceCall(this.changeTemporaryPasswordForm.value).subscribe(res => {
    const response = res.json();
    if (response.statusId == 2000) {
      this.toastr.success(response.statusMessage, 'Success!');
      this.disableBtn = false;
      // sessionStorage.removeItem(AppSettings.isLoggedInKey);
      // sessionStorage.setItem(AppSettings.isLoggedInKey, this.encryptionService.set(AppSettings.XPR_TOS_KEY_TEST, response.statusId));
      sessionStorage.clear();
      this.hideChangeTemporaryPasswordForm = true;
      this.changeTemporaryPasswordForm.reset();
      this.router.navigate(['/login']);
      // location.reload();
      this.spinner.hide();
    } else {
      this.toastr.error(response.statusMessage, 'Alert!');
    }
    this.spinner.hide();
  }, error => {
    this.spinner.hide();
    console.log(error);
    this.toastr.error('Attempt to connect to our server has failed., please try again', 'Communication Error');
  });

}

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxPopupModule, FormsModule, ReactiveFormsModule,
    AngularMaterial
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent]
})
export class LoginFormModule { }
