import { NgModule } from '@angular/core';

const MaterialComponents = [
];

@NgModule({
  imports: [],
  exports: []
})
export class AngularMaterial { }
