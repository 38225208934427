import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})
export class ServiceProviderComponent extends SharedClassComponent implements OnInit {
  //variables defination
  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = 'Service Provider Category';
  model_name: string = 'sp/category';

  ngOnInit() {
      // sets the current page title in index.html title tag
    this.appInfo.setTitle('Service Provider Category');

    this.paramwinfrm =  new FormGroup({
      requestType: new FormControl('SP_SERVICE_CATEGORY_CREATE', Validators.compose([])),
      catId: new FormControl('', Validators.compose([])),
      catName: new FormControl('', Validators.compose([Validators.required])),
      catSpId: new FormControl('', Validators.compose([Validators.required])),
      status: new FormControl('', Validators.compose([]))
    });
    this.getServiceProviderCategories();
    this.getServiceProviders();
    this.observerCall();
  }
  refreshDataGrid() {
    this.getServiceProviderCategories();
    this.getServiceProviders();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New Category',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewParameter.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.refreshDataGrid.bind(this)
      }
    });

  }
}
