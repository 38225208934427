import { Component, OnInit } from '@angular/core';

import {  DxContextMenuModule, DxMenuModule } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedClassComponent } from '../../shared-class/shared-class.component';
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent extends SharedClassComponent implements OnInit {
  title:string= 'User Roles';
  model_name:string='roles';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  paramsDataSets:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      is_enabled: new FormControl('', Validators.compose([])),
      is_super_group: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([])),
      role_id: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
    // Controls the datagrid height and max rows to display
    this.observerCall();

  }
  funcGetCheckValue(is_enabled){

      if(is_enabled == 1){
        return true;
      }else{
        return false;
      }
  }
}
