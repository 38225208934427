import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import init from '../../../assets/data/init.json';

@Injectable()
export class AppInfoService {

  DisableEnabledTextSwitcher: any;

  constructor(private titleService: Title) {}

  public setTitle( newTitle: string) {
    this.titleService.setTitle(`${init.appName} | ${newTitle}`);
  }

  public get title() {
    return init.appName;
  }

  public setTextSwitcher(text: any) {
    this.DisableEnabledTextSwitcher = text;
  }

  public getTextSwitcher() {
    return this.DisableEnabledTextSwitcher;
  }

}
