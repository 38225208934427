import { Component, OnInit, Output, EventEmitter, NgModule } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss']
})
export class BillDetailsComponent extends SharedClassComponent implements OnInit {

  @Output() onClick = new EventEmitter();

  CurrentRoute = this.router.url;

  ngOnInit() {
    this.onGetClearedBillsdetails();
    // this.onGetPendingBillsdetails();
    // this.onGetPendingRejecteddetails();
  }
}

