import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import {confirm} from 'devextreme/ui/dialog';

@Component({
  selector: 'app-bill-check',
  templateUrl: './bill-check.component.html',
  styleUrls: ['./bill-check.component.scss']
})
export class BillCheckComponent extends SharedClassComponent implements OnInit {

  title = '';
  billStatusId: any;
  eligibleToPushBill = false;
  billForm: FormGroup;
  cbsForm: FormGroup;
  billCheckEndpoint = 'realbus_api/sx/bill/status';
  billPushEndpoint = 'realbus_api/sx/bill/push';
  gateway = 'Gateway Status';
  showPopDialog = false;
  dialogTitle = "Enter CBS Reference For Control number";
  billStatusMsg = '';
  validationStatusMsg = '';
  cbsStatusMsg = '';
  gatewayStatusMsg = '';
  callBackStatusMsg = '';
  billStatusDataSource = [
    {
      controlNumber: null,
      billValid: null,
      validationSuccess: null,
      cbsPostSuccess: null,
      gatewayPostSuccess: null,
      callbackSuccess: null,
      billPush: null
    }
  ];

  validationRequestDataSource = [];
  validationResponseDataSource = [];
  cbsRequestDataSource = [];
  cbsResponseDataSource = [];
  clearanceRequestDataSource = [];
  clearanceResponseDataSource = [];
  callbackDataSource = [];
  hideBlindBillPush = true;

  ngOnInit() {
    this.billForm = new FormGroup({
      controlNumber: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.cbsForm = new FormGroup({
      cbsRefNo: new FormControl(null, Validators.compose([Validators.required]))
    });
  }

  showDialogAction() {
    this.showPopDialog = true;
  }

  checkBillStatus() {
    if (this.billForm.invalid) {
      this.toastr.error('Invalid control number');
      return;
    }

    if (`${this.billForm.get('controlNumber').value}`.trim() == '' || this.billForm.get('controlNumber').value == null) {
      this.toastr.error('Control number is empty');
      return;
    }
    this.spinner.show();
    const data = {
      controlNo: `${this.billForm.get('controlNumber').value}`.trim()
    };
    this.clearData();
    this.utilities.txnPushpPostServiceCall(data, this.billCheckEndpoint).subscribe(res => {
      const response = res.json();
      this.spinner.hide();
      this.hideBlindBillPush = true;
      if (response.statusId == 2000) {
        this.billStatusId = response.data.responseId;
        this.billStatusDataSource[0].controlNumber = this.billForm.get('controlNumber').value;
        this.billStatusDataSource[0].billValid = response.data.statusSummary.billStatus;
        this.billStatusDataSource[0].validationSuccess = response.data.statusSummary.billStatus;
        this.billStatusDataSource[0].cbsPostSuccess = response.data.statusSummary.cbsPostStatus;
        this.billStatusDataSource[0].gatewayPostSuccess = response.data.statusSummary.clearanceStatus;
        this.billStatusDataSource[0].callbackSuccess = response.data.statusSummary.callbackStatus;
        this.billStatusDataSource[0].billPush = response.data.statusSummary.pushStatus;

        this.billStatusMsg = response.data.statusSummary.clearanceStatusMsg;
        this.validationStatusMsg = response.data.statusSummary.billStatusMsg;
        this.cbsStatusMsg = response.data.statusSummary.cbsPostStatusMsg;
        this.gatewayStatusMsg = response.data.statusSummary.clearanceStatusMsg;
        this.callBackStatusMsg = response.data.statusSummary.callbackStatusMsg;

        if (response.data.statusSummary.pushStatus == 0) {
            this.eligibleToPushBill = false;
            if (response.data.statusSummary.billStatus == 1) {
              this.hideBlindBillPush = false;
            }
            // this.toastr.error(response.data.statusSummary.pushStatusMsg, response.data.statusSummary.billStatusMsg);
          } else {
            this.eligibleToPushBill = true;
            // this.blindBillPush = true;
            // this.toastr.success(response.data.statusSummary.pushStatusMsg, response.data.statusSummary.billStatusMsg);
          }
        try {
          this.gateway = `Gateway Status (${response.data.dataRecords.validationResponses[0].gatewayCode})`;
        } catch (error) {

        }
        this.validationRequestDataSource =  response.data.dataRecords.validationRequests;
        this.validationResponseDataSource =  response.data.dataRecords.validationResponses;
        this.cbsRequestDataSource =  response.data.dataRecords.cbsRequests;
        this.cbsResponseDataSource =  response.data.dataRecords.cbs_responses;
        this.clearanceRequestDataSource =  response.data.dataRecords.clearanceRequests;
        this.clearanceResponseDataSource =  response.data.dataRecords.clearanceResponses;
        this.callbackDataSource =  response.data.dataRecords.callbacks;
      } else {
        this.toastr.error(response.statusMessage);
        this.gateway = 'Gateway Status';
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error('Error occured while processing the request.', 'Request Failed');
      this.gateway = 'Gateway Status';
    } );
  }

  pushControlNumberUnsafe() {
    if (this.billForm.invalid) {
      this.toastr.error('Control number is required');
      return;
    }

    if (`${this.billForm.get('controlNumber').value}`.trim() == '' || this.billForm.get('controlNumber').value == null) {
      this.toastr.error('Control number is empty');
      return;
    }
    if (`${this.cbsForm.get('cbsRefNo').value}`.trim() == '' || this.cbsForm.get('cbsRefNo').value == null) {
      this.toastr.error('CBS reference is empty');
      return;
    }

    if (this.cbsForm.invalid) {
      this.toastr.error('CBS Reference is required.');
      return;
    }

    const result = confirm(
      `Warning, pushing this transaction without confirming CBS entries for this control number(${this.billForm.get('controlNumber').value})\n may result in bill clearance at gateway level without a transaction being in the CBS,\n if you continue, you acknowledge that, the transaction is present in the CBS. \n System will record your OPF number and CBS Reference as a proof of user's consent. \nClick YES to continue or No to cancel.`,
      'User\'s consent.'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.spinner.show();
        const data = {
          controlNo: `${this.billForm.get('controlNumber').value}`.trim(),
          billStatusId: `${this.billStatusId}`,
          cbsRefNo: `${this.cbsForm.get('cbsRefNo').value}`.trim()
        };
        this.showPopDialog = false;
        this.utilities.txnPushpPostServiceCall(data, this.billPushEndpoint).subscribe(res => {
          this.spinner.hide();
          const apiResponse = res.json();
          if (apiResponse.statusId == 2000) {
            this.clearData();
            this.toastr.success(`Control number ${data.controlNo} is pushed successfully to ${this.gateway}`, `${this.gateway} bill Push success`);
            this.billForm.reset();
          } else {
            this.toastr.error(apiResponse.statusMessage);
          }
        }, error => {
          this.toastr.error(error, 'Request Failed');
        });
      }
    });
  }


  pushControlNumberSafe() {
    if (this.billForm.invalid) {
      this.toastr.error('Invalid control number');
      return;
    }

    if (`${this.billForm.get('controlNumber').value}`.trim() == '' || this.billForm.get('controlNumber').value == null) {
      this.toastr.error('Control number is empty');
      return;
    }
        this.spinner.show();
        const data = {
          controlNo: `${this.billForm.get('controlNumber').value}`.trim(),
          billStatusId: `${this.billStatusId}`,
          cbsRefNo: '123456789123456'
        };
        this.utilities.txnPushpPostServiceCall(data, this.billPushEndpoint).subscribe(res => {
          this.spinner.hide();
          const apiResponse = res.json();
          if (apiResponse.statusId == 2000) {
            this.clearData();
            this.toastr.success(`Control number ${data.controlNo} is pushed successfully to ${this.gateway}`, `${this.gateway} bill Push success`);
            notify('Please wait for a few seconds for GePG callback',  'info', 10000);
            this.billForm.reset();
          } else {
            this.toastr.error(apiResponse.statusMessage);
          }
        }, error => {
          this.toastr.error(error, 'Request Failed');
        });
  }

  clearData() {
    this.billStatusId = '';
    this.gateway = '';
    this.hideBlindBillPush = true;
    this.billStatusDataSource = [
      {
        controlNumber: null,
        billValid: null,
        validationSuccess: null,
        cbsPostSuccess: null,
        gatewayPostSuccess: null,
        callbackSuccess: null,
        billPush: null
      }
    ];
    this.validationRequestDataSource = [];
    this.validationResponseDataSource = [];
    this.cbsRequestDataSource = [];
    this.cbsResponseDataSource = [];
    this.clearanceRequestDataSource = [];
    this.clearanceResponseDataSource = [];
    this.callbackDataSource = [];
    this.eligibleToPushBill = false;
  }

}
