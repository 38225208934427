import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppSettings } from 'src/app/app-settings';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-revenue-transaction',
  templateUrl: './revenue-transaction.component.html',
  styleUrls: ['./revenue-transaction.component.scss']
})
export class RevenueTransactionComponent extends SharedClassComponent implements OnInit   {
  title: string = 'Generated Bills';

  tab_paneldata: any = [
    {
    ID: 1,
    icon: 'fa fa-check-circle',
    name: 'Valid Bills'
    },
    {
    ID: 2,
    icon: 'fa fa-check-circle',
    name: 'Cleared Bills'
    },
    {
    ID: 3,
    icon: 'fa fa-check-circle',
    name: 'Inactive Bills'
    }
  ];
  allClearedBillsEndPoint  = 'bills/cleared/all';
  getClearedBillsByDateRangeEndpoint = 'bills/cleared/all/date/specific';

  getAllBillsEndpoint = 'bills/sp';
  validBills = [];
  clearedBills = [];
  inactiveBills = [];

  endpointName = 'portal/request';
  currentDate: any;
  currentUser;
  now: Date = new Date();

  popUpDialogVisible = false;
  openSummaryPopDialog = false;

  clearedBillsDataSource = [];
  incomingBills = [];
  billItemsDatasource = [];

  tzsAmountCounter: number = 0;
  usdAmountCounter: number = 0;
  tzsTxnCounter = 0;
  usdTxnCounter = 0;

  reportSummaryDataSource = [{
    totalTxn: 0,
    tzsTxn: 0,
    usdTxn: 0,
    tAmtTzs: 0,
    tAmtUsd: 0,
    recordDate: ''
  }];

  oneDayAhead = new Date();
  today = new Date();
  userFirstName = this.authService.getUserDetails().firstName;
  userId = this.authService.getUserDetails().userId;

  ngOnInit() {
  //  this.spinner.show();
   this.toastr.info('Data loading in progress', 'Please wait');
   // sets the current page title in index.html title tag
   this.appInfo.setTitle(this.title);
   this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
   this.paramwinfrm = new FormGroup({
     startDate: new FormControl(this.today, Validators.compose([Validators.required])),
     endDate: new FormControl(this.today, Validators.compose([Validators.required]))
   });

   this.getAllClearedBills();
   // Controls the datagrid height and max rows to display
   this.observerCall();

  }
  customizeDate(data) {
    return 'From: ' + new DatePipe('en-US').transform(data.value, 'dd-MM-yyyy') + ' to ' + this.currentDate;
}
closePopUp() {
  this.popUpDialogVisible = false;
}
openSummaryDialog() {
  this.openSummaryPopDialog = true;
}
closeOpenSummaryPopDialog() {
  this.openSummaryPopDialog = false;
}

getAllClearedBills() {
  this.spinner.show();
  const data = {
    "requestType": "BILLS_LIST",
    "spId": this.authService.getUserDetails().spId
  };
  this.utilities.postServiceCall(data).subscribe(
    response => {
      const res = response.json();

      if (res.statusId == 2000) {
        this.validBills = res.data.validBills;
        this.clearedBills = res.data.clearedBills;
        this.inactiveBills = res.data.inactiveBills;
        console.log(this.inactiveBills)
      } else {
        this.toastr.error(res.statusMessage);
      }

      this.spinner.hide();
      this.toastr.info('Data has finished loading', 'Process Completed');
    },
    error => {
      this.spinner.hide();
      this.toastr.error(
        'Error occurred while processing the request',
        'Request Failed'
      );
    }
  );
}

getClearedBillsByDateRange() {
  if (this.paramwinfrm.invalid) {
    this.toastr.error("Start Date and end Date is required.");
    return;
  }
  this.spinner.show();
  const startDatestring = this.paramwinfrm.get('startDate').value.getFullYear() + "-"+  ("0"+(this.paramwinfrm.get('startDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.paramwinfrm.get('startDate').value.getDate()).slice(-2);

  const dt = new Date(this.paramwinfrm.get('endDate').value).setDate(new Date(this.paramwinfrm.get('endDate').value).getDate() + 1);

  const dd = String(new Date(dt).getDate()).padStart(2, '0');
  const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0'); // January is 0
  const yyyy = new Date(dt).getFullYear();
  const endDatestring = yyyy + '-' + mm + '-' + dd;

  if (new Date(startDatestring) > new Date(endDatestring)) {
    this.toastr.error("Start Date is greater than end Date.");
    this.spinner.hide();
    return;
  }
  const data = {
      startDate: startDatestring,
      endDate: endDatestring
  };
  this.utilities.postServiceCallWithoutAuth(data, this.getClearedBillsByDateRangeEndpoint).subscribe(
    response => {
      const res = response.json();
      this.clearedBillsDataSource = res.data;

      try {
        this.amountCounter(this.clearedBillsDataSource);
        this.reportSummaryDataSource[0] = {
        totalTxn: this.clearedBillsDataSource.length,
        tzsTxn: this.tzsTxnCounter,
        usdTxn: this.usdTxnCounter,
        tAmtTzs: this.tzsAmountCounter,
        tAmtUsd: this.usdAmountCounter,
      // tslint:disable-next-line: max-line-length
        recordDate: 'From: ' + new DatePipe('en-US').transform(this.clearedBillsDataSource[this.clearedBillsDataSource.length - 1].created_at, 'yyyy-MM-dd') + ' to ' + this.currentDate
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
      }
      this.spinner.hide();
      this.toastr.info('Data has finished loading', 'Process Completed');
    },
    error => {
      this.spinner.hide();
      this.toastr.error(
        'Error occurred while processing the request',
        'Request Failed'
      );
    }
  );
}

amountCounter(data) {
  this.tzsAmountCounter = 0;
  this.usdAmountCounter = 0;
  this.tzsTxnCounter = 0;
  this.usdTxnCounter = 0;
  for (const el of data) {
    if (el.currency == 'TZS') {
      this.tzsAmountCounter += el.amount;
      this.tzsTxnCounter += 1;
    }
    if (el.currency == 'USD') {
      this.usdAmountCounter += el.amount;
      this.usdTxnCounter += 1;
    }
  }
}

toolBarPreparing(e, refresh_action) {
  e.toolbarOptions.items.unshift(
    {
      location: 'before',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'help',
        hoverStateEnabled: false,
        hint: 'Double click on the rows to Preview & Print Bill receipt'
      }
    },
    {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
        onClick: refresh_action.bind(this)
      }
    }
  );
}

printOrderForm(e) {
  this.billItemsDatasource = [];

            if (e.billItems.length < 1) {
              this.billItemsDatasource.push({
                itemAmount: e.billAmount,
                description: e.description,
                itemCatName: e.catName,
                itemCurrency: e.currency
              });
            } else {
              for (const iterator of e.billItems) {
                this.billItemsDatasource.push({
                  itemAmount: iterator.amount,
                  description: iterator.description,
                  itemCatName: iterator.categoryName,
                  itemCurrency: e.currency
                });
              }
            }
            sessionStorage.removeItem(AppSettings.billDetailsKey);
            sessionStorage.removeItem(AppSettings.billItemsKey);
            sessionStorage.removeItem(AppSettings.bulkBillerKey);
            sessionStorage.removeItem(AppSettings.bulkBillerFlag);
            sessionStorage.setItem(AppSettings.bulkBillerFlag,  '0');
            sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(e));
            sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
            this.router.navigate(['/bill-payment-form']);
}

previewBill(e) {
  const result = confirm('Please click the appropriate action to continue.','Action');
}

editBill(e) {
  sessionStorage.removeItem(AppSettings.billEditKey);
  sessionStorage.setItem(AppSettings.billEditKey, JSON.stringify(e));
  this.router.navigate(['/bill/create'], { queryParams: { billId: e.billId }, queryParamsHandling: 'merge'});
}

approveCreatedBills(e, status) {
  const data = {
    requestType: 'BILL_ENABLE',
    billId: `${e.billId}`,
    billStatus: `${status}`
  };

  const declineBillConfirmMessage = `Dear ${this.userFirstName}, are you sure you want to decline bill with control number ${e.controlNumber} ?`;
  const declineBillConfirmTitle = `Decline Bill`;

  const approveBillConfirmMessage = `Dear ${this.userFirstName}, are you sure you want to approve bill with control number ${e.controlNumber} ?`;
  const approveBillConfirmTitle = `Approve Bill`;

  const confirmMessage = status == 1 ? approveBillConfirmMessage : declineBillConfirmMessage;
  const confirmTitle = status == 1 ? approveBillConfirmTitle : declineBillConfirmTitle;

  const result = confirm(confirmMessage,confirmTitle);

  result.then(dialogResult => {
    if (dialogResult) {
      this.spinner.show();
      this.utilities.postServiceCall(data).subscribe(res => {
        const response = res.json();
        if (response.statusId == 2000) {
          if (status == 1) {
            this.toastr.success('Bill has been aprroved successfully', 'Bill Approval');
            // send to bill printing page
            this.billItemsDatasource = [];

            if (e.billItems.length < 1) {
              this.billItemsDatasource.push({
                itemAmount: e.billAmount,
                itemDesc: e.billDesc,
                itemCatName: e.catName,
                itemCurrency: e.currency
              });
            } else {
              for (const iterator of e.billItems) {
                this.billItemsDatasource.push({
                  itemAmount: iterator.amount,
                  itemDesc: iterator.desc,
                  itemCatName: iterator.categoryName,
                  itemCurrency: e.currency
                });
              }

            }
            sessionStorage.removeItem(AppSettings.billDetailsKey);
            sessionStorage.removeItem(AppSettings.billItemsKey);
            sessionStorage.removeItem(AppSettings.bulkBillerKey);
            sessionStorage.removeItem(AppSettings.bulkBillerFlag);
            sessionStorage.setItem(AppSettings.bulkBillerFlag,  '0');
            sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(e));
            sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
            this.router.navigate(['/bill-payment-form']);
          } else {
            this.getAllClearedBills();
            this.toastr.success('Bill has been declined successfully', 'Bill Decline');
          }
        } else {
          this.toastr.error(response.statusMessage);
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }
  });
}

onBillDoubleClickDetails(e, is_hidden) {
  this.spinner.show();
  const payload = {
    user_id: e.data.created_by
  };
  this.utilities.postServiceCall(payload, 'users/get').subscribe(res => {
    const response = res.json();
    this.spinner.hide();
    if (response.statusId == 2000) {
      const data = {
        branch_id: response.data.branch
      };
      this.username = response.data.username;
      this.utilities.postServiceCall(data, 'branches/get').subscribe( res2 => {
        const serverRes = res2.json();
        this.spinner.hide();
        if (serverRes.statusId == 2000) {
          this.branch = serverRes.data.name;
          this.previewIshiddenField = true;
          this.hideprintBtn = is_hidden;
          const bill_data = e.data;
          this.previewBillsWin = true;
          this.previewBillsFrm.patchValue(bill_data);
          this.clearance_request_id = bill_data.clearance_request_id;
          this.gateway_code = bill_data.gateway_code;
          this.currency = bill_data.currency;
          this.control_number = bill_data.control_number;
          this.gateway_receipt_number = bill_data.gateway_receipt_number;
          this.payment_option = bill_data.payment_option;
          this.receipt_number = bill_data.receiptNo;
          this.psp_reference_number = bill_data.psp_reference_number;
          this.amount = bill_data.amount;
          this.description = bill_data.description;
          this.sp_account_number = bill_data.sp_account_number;
          this.date = bill_data.created_at;
          this.created_by = bill_data.created_by;
          this.sp_code = bill_data.sp_code;
          this.sp_name = bill_data.sp_name;
          this.customer_name = bill_data.customer_name;
          this.status_message = bill_data.status_message;
          this.gateway_code = bill_data.gateway_code;
        }
      }, error2 => {
        this.spinner.hide();
        this.toastr.error('Something went wrong, while creating a payment receipt, please try again.');
      });
    }
  }, error => {
    this.spinner.hide();
  });

}

customizeCount(data) {
  return 'Total Transactions: ' + data.value;
}

onPrintBillreceipt() {
  this.previewIshiddenField = false;
  let popUpWindow;
  let innerContents = document.getElementById('print-container').innerHTML;
  popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
 // popupWinindow.document.open();
  popUpWindow.document.write(
   `<html>
   <head>
   <link rel="stylesheet" type="text/css" href="style.css" />
   <style>
   @media print {
    @page {
      size: A4;
      margin: 0mm;
    }

    html, body {
      width: 1024px;
    }

    body {
      margin: 0 auto;
      line-height: 1em;
      word-spacing:2px;
      letter-spacing:0.2px;
      font: 20px "Times New Roman", Times, serif;
      color:black;
      width: 100%;
      float: none;
      padding: 2rem;
    }


    /* avoid page-breaks inside a listingContainer*/
    .listingContainer{
      page-break-inside: avoid;
    }
    h1 {
        font: 28px "Times New Roman", Times, serif;
      }

      h2 {
        font: 24px "Times New Roman", Times, serif;
      }

      h3 {
        font: 20px "Times New Roman", Times, serif;
      }

      / Improve colour contrast of links /
      a:link, a:visited {
        color: #781351
      }

      / URL /
      a:link, a:visited {
        background: transparent;
        color:#333;
        text-decoration:none;
      }

      a[href]:after {
        content: "" !important;
      }
      a[href^="http://"] {
        color:#000;
      }
    }
   </style>
   </head>
   <body onload="window.print()"> ${innerContents} </html>`
   );
  popUpWindow.document.close();
  this.previewBillsWin = false;
}
}
