import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, HeaderComponent } from './shared/components';
import {
  AuthGuardService,
  AuthGuardServiceAdminsOnly
} from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { AnQrcodeModule } from 'an-qrcode';
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxButtonModule,
  DxPopupModule,
  DxActionSheetModule,
  DxFileUploaderModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxHtmlEditorModule,
  DxDropDownBoxModule,
  DxTagBoxModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxBoxModule,
  DxPieChartModule,
  DxChartModule,
  DxValidatorModule,
  DxLookupModule
} from 'devextreme-angular';
import { UserManagementComponent } from './pages/system-administration/user-management/user-management.component';
import { UserRolesComponent } from './configurations/user-management/user-roles/user-roles.component';
import { AccessLevelComponent } from './configurations/user-management/access-level/access-level.component';
import { NavigationTypesComponent } from './configurations/systemaccess/navigation-types/navigation-types.component';
import { NavigationLevelsComponent } from './configurations/systemaccess/navigation-levels/navigation-levels.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogueComponent } from './utilities/confirmation-dialogue/confirmation-dialogue.component';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from './shared/services/utilities.service';
import { SharedClassComponent } from './configurations/shared-class/shared-class.component';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { UserStatusesComponent } from './configurations/user-management/user-statuses/user-statuses.component';
import { BankBranchesComponent } from './configurations/systemaccess/bank-branches/bank-branches.component';
import { BillValidationComponent } from './bill-management/bill-validation/bill-validation.component';
import { NavigationsComponent } from './configurations/systemaccess/navigations/navigations.component';
import { PermissionsComponent } from './configurations/systemaccess/permissions/permissions.component';
import { RevenueTransactionComponent } from './bill-management/reports/revenue-transaction.component';
import { CurrencyDenominationsComponent } from './configurations/currency-denominations/currency-denominations.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountTypesComponent } from './account/account-types/account-types.component';
import { AccountsComponent } from './account/accounts/accounts.component';
import { GatewayComponent } from './gateway/gateway.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { TemporaryLoginComponent } from './shared/components/temporary-login/temporary-login.component';
import { DataVisualizationComponent } from './data-visualization/data-visualization.component';
import { BillDetailsComponent } from './bill-management/bill-details/bill-details.component';
import { BillCheckComponent } from './bill-management/bill-check/bill-check.component';
import { IncomingTissComponent } from './rtgs/reports/incoming-tiss/incoming-tiss.component';
import { OutgoingTissComponent } from './rtgs/reports/outgoing-tiss/outgoing-tiss.component';
import { OutgoingTissResponseComponent } from './rtgs/reports/outgoing-tiss-response/outgoing-tiss-response.component';
import { ReconciliationStatusComponent } from './reconciliation/reconciliation-status/reconciliation-status.component';
import { BillPaymentFormComponent } from './bill-payment-form/bill-payment-form.component';
import { BulkBillCreateComponent } from './bulk-bill-create/bulk-bill-create.component';
import { ServiceProviderAccountsComponent } from './service-provider-accounts/service-provider-accounts.component';
import { BillReportDateRangeComponent } from './bill-management/reports/bill-report-date-range/bill-report-date-range.component';
//import { BillDetailsByControlNumberComponent } from './bill-management/reports/bill-details-by-control-number/bill-details-by-control-number.component';
import { ExpiredBillReportComponent } from './bill-management/reports/expired-bill-report/expired-bill-report.component';
import { AuditTrailReportComponent } from './bill-management/reports/audit-trail-report/audit-trail-report.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InformationComponent } from './information/information.component';

const routes: Routes = [
  {
    path: 'reports/bills/listing',
    component: BillReportDateRangeComponent,
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'reports/bills/details',
  //   component: BillDetailsByControlNumberComponent,
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'reports/bills/expired',
    component: ExpiredBillReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports/audit-trail',
    component: AuditTrailReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'recon-status',
    component: ReconciliationStatusComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'outgoing-tiss',
    component: OutgoingTissComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'currency-denominations',
    component: CurrencyDenominationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'bills/status',
    component: BillCheckComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'utilities/confirmation-dialogue',
    component: ConfirmationDialogueComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'navigation-levels',
    component: NavigationLevelsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'navigation-types',
    component: NavigationTypesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'access-level',
    component: AccessLevelComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'user-roles',
    component: UserRolesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'bill-payment-form',
    component: BillPaymentFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'services/providers/accounts',
    component: ServiceProviderAccountsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'bulk/bill/create',
    component: BulkBillCreateComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'display-data',
    component: DisplayDataComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'navigations-setup',
    component: NavigationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'permissions-setup',
    component: PermissionsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'generated-bills',
    component: RevenueTransactionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'data-visualization',
    component: DataVisualizationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-statuses',
    component: UserStatusesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'register/institution',
    component: BankBranchesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'bill/create',
    component: BillValidationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'currency-denominations',
    component: CurrencyDenominationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'sp/categories',
    component: ServiceProviderComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'gateways',
    component: GatewayComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'account-types',
    component: AccountTypesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'change-temporary-password',
    component: TemporaryLoginComponent
  },
  {
    path: 'information',
    component: InformationComponent
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,  { useHash: true }),
    DxDataGridModule,
    DxFormModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxActionSheetModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxContextMenuModule,
    DxMenuModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    NgbModalModule,
    DxTabPanelModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxPieChartModule,
    DxChartModule,
    NgHttpLoaderModule.forRoot(),
    ModalDialogModule.forRoot(),
    DxRadioGroupModule,
    DxValidatorModule,
    DxLookupModule,
    AnQrcodeModule,
  ],
  providers: [AuthGuardService, UtilitiesService, AuthGuardServiceAdminsOnly],
  exports: [RouterModule],
  declarations: [
    BillCheckComponent,
    HomeComponent,
    ProfileComponent,
    DisplayDataComponent,
    UserManagementComponent,
    UserRolesComponent,
    AccessLevelComponent,
    NavigationTypesComponent,
    NavigationLevelsComponent,
    SharedClassComponent,
    UserStatusesComponent,
    BankBranchesComponent,
    BillValidationComponent,
    NavigationsComponent,
    PermissionsComponent,
    RevenueTransactionComponent,
    CurrencyDenominationsComponent,
    SettingsComponent,
    AccountTypesComponent,
    AccountsComponent,
    GatewayComponent,
    ServiceProviderComponent,
    TemporaryLoginComponent,
    DataVisualizationComponent,
    BillDetailsComponent,
    IncomingTissComponent,
    OutgoingTissComponent,
    OutgoingTissResponseComponent,
    ReconciliationStatusComponent,
    BillPaymentFormComponent,
    BulkBillCreateComponent,
    ServiceProviderAccountsComponent,
    BillReportDateRangeComponent,
    //BillDetailsByControlNumberComponent,
    ExpiredBillReportComponent,
    AuditTrailReportComponent,
    UserProfileComponent,
    InformationComponent
  ]
})
export class AppRoutingModule {}
