import init from '../assets/data/init.json';

export class AppSettings {

public static channel_id = '3001';
public static channel_code = 'PT001';
public static channel_security_code = 'password';

public static ip = '172.20.1.63';
  // For LIVE  PBZ RTGS REPORTS -  TOMCAT
  public static rtgsApiCall = init.rtgsApiCall;

  public static pushCheckBaseUrl = init.pushCheckBaseUrl;

  public static operationMode = 2;
  public static apiName = 'realcs_api/cx/'; // live

  // Local
  // public static phpInterceptor = 'http://localhost/interceptor/';

  public static scope = 'SP';

  public static appNme = init.appName;

  // Available scopes 1.SP 2.ADMIN

  // Remote Local
  //https://api.uax.app:8183/cx/
   //public static phpInterceptor ='https://151.236.222.92:31001/cx/'
   public static phpInterceptor ='https://api.uax.app:8183/cx/'
  //public static phpInterceptor = 'http://172.20.1.63/interceptor/';
  //public static phpInterceptor = 'https://321e-154-127-10-10.ngrok.io/interceptor/';
  //
  
  // Remote Live
  // public static phpInterceptor = 'https://dcbmalipo.dcb.co.tz/interceptor/';

  // 1 - for PBZ
  // 2 for client/
  // any other number - general menu
  public static oraclebase_url = init.oraclebase_url;

  // Storage keys live
  public static isLoggedInKey = '$2y$18$6MWbDSFZ7mSeRu/m/dfa.ekN7JYPaOwgenZNIWhaoXtpczEIMd/Ii';
  public static sessionKey = '$2y$18$pt36boBQmFt84UOnQC7Lfud5nlWIXUPBv5P259/mDJ12APfSGQI.K';
  public static userDataKey = '$2y$18$/d5.3e4OtdLllt2EkYEkK.Cb75dFV1lJ5AuiB9DjJdgrhm.8y1op.';

  public static XPR_TOS_KEY_TEST = '985243$#@$^@1ERF';
  public static billDetailsKey = 'a232-jy765868-46e5-a53d';
  public static billItemsKey = 'sdff9-sdff8-sfss9d7ss-sefs';

  public static shule = 'MbQeThVmYq3t6w9z$C&F)J@NcRfUjXnZ';

  public static bulkBillerKey = '3e4OtdLllt2EkYEkK$2y$18$6MWbDSFZ7mSeRu/m/dfa';

  public static bulkBillerFlag = 'Cb75dFV1lJ5jhgyujsd67437688hjdsfj';

  public static billEditKey = '4OtdLllt2EkYEkK.Cb75dFV1lJ5AuiB9D';

}
