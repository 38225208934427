import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DxContextMenuModule, DxMenuModule, DxPopupModule, DxDataGridModule } from 'devextreme-angular';
import { LayoutModule } from '@angular/cdk/layout';
import { OnlineServicesModule } from './modules/online-services/online-services.module';
import { PublicServicesModule } from './modules/public-services/public-services.module';
import { ConfirmationDialogueComponent } from './utilities/confirmation-dialogue/confirmation-dialogue.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PushReconDateComponent } from './reconciliation/push-recon-date/push-recon-date.component';
import { PushReconExcelComponent } from './reconciliation/push-recon-excel/push-recon-excel.component';
import { ReconciliationComponent } from './reconciliation/reconciliation.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InformationComponent } from './information/information.component';



@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogueComponent,
    PushReconDateComponent,
    PushReconExcelComponent,
    ReconciliationComponent,
    
    
  ],
  imports: [
    DxContextMenuModule,
    DxMenuModule,
    DxDataGridModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    DxPopupModule,
    FooterModule,
    OnlineServicesModule,
    PublicServicesModule,
    LoginFormModule,
    NgHttpLoaderModule.forRoot(),
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AngularFontAwesomeModule
  ],
  entryComponents: [ConfirmationDialogueComponent],
  providers: [AuthService, ScreenService, AppInfoService, HttpClientModule, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
