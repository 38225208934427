import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';


@Component({
  selector: 'app-bill-payment-form',
  templateUrl: './bill-payment-form.component.html',
  styleUrls: ['./bill-payment-form.component.scss']
})
export class BillPaymentFormComponent  extends SharedClassComponent implements OnInit {

  contributedMembers = [];
  hideMembersContributionList = true;

  invoiceForm: FormGroup;
  qrCodeString = '';
  apiCallEndpoint = 'fumis_bill/create';
  hidePrintInvoiceButton = true;
  hidePrintMembersButton = true;
  hideGenerateControlNumberButton = true;
  hideEditInvoiceButton = true;
  hideCancelInvoiceButton = true;
  hideNoContributionMadeForInvoiceBanner =  true;
  hideInvoiceSheet = true;
  hideQrCode = true;
  membersContributionRouterLink = '/contribution/upload';

  isInvoiceCreated = true;
  isInvoicePostedForPayment = false;
  isInvoicePrinted = false;
  isPenaltyInvoice = false;

  // invoice items
  controlNumber: any;
  billDescription = '';
  totalAmountPaid: any;
  payableBanks = '';
  invoiceNumber: any;
  totalAmountContributed: any;
  employerNumber: any;
  payerName: any;
  employerPhone: any;
  employerEmail: any;
  paymentType: any;
  invoiceDescription: any;
  invoiceCurrency: any;
  amountInWords = '';
  billExpireDate = '';
  invoiceBillItems = [];
  billCreationDate: any;
  now: any;
  currentYear = new Date().getFullYear();
  openInvoiceEditPopUp = false;
  contributionBatchData: any;
  isGeneratedContributionBatch = false;
  invoiceDataSource = {
      contributionInvoice: null,
      invoicePenalties: null
    };
  invoiceID = '';

 ID: any;
 DESCRIPTION: any;
 CURRENCY: any;
 AMOUNT: any;
 userGroup: any;
 hideViewControls = false;
 individualBillDescription: any;
 spName =  this.authService.getUserDetails().spName;

 billData: any;


 hidePrintBtn = true;
 hideMultipleBillPrint = true;
 appName = '';
 hideBulkPrint = '0';
  expireDate: any;
  pmtOptName: any;
  ngOnInit() {
    this.appName = this.appInfo.title;
    this.appInfo.setTitle('Bill Order Form');
    if (sessionStorage.getItem(AppSettings.bulkBillerFlag) !== null && sessionStorage.getItem(AppSettings.bulkBillerFlag) !== undefined &&
    sessionStorage.getItem(AppSettings.bulkBillerFlag) == '1') {
     this.hideBulkPrint = '1';
     this.hideMultipleBillPrint = false;
    }

    if ((sessionStorage.getItem(AppSettings.billDetailsKey) === null || sessionStorage.getItem(AppSettings.billDetailsKey) === undefined) &&
    (sessionStorage.getItem(AppSettings.bulkBillerKey) === null || sessionStorage.getItem(AppSettings.bulkBillerKey) === undefined)) {
      this.toastr.error('No bill information.');
      return;
    }

    if (this.hideBulkPrint == '0') {
      try {
        this.billData = JSON.parse(sessionStorage.getItem(AppSettings.billDetailsKey));

        this.invoiceBillItems = JSON.parse(sessionStorage.getItem(AppSettings.billItemsKey));
        this.hideQrCode = false;
        this.qrCodeString = `{
          "controlNumber": "${this.billData.controlNumber}",
          "payerName": "${this.billData.payerName}",
          "totalAmount": ${this.billData.billAmount},
          "currency": "${this.billData.currency}",
          "expireDate": "${this.billData.expireDate}"
        }`;
        this.controlNumber = this.billData.controlNumber;
        this.totalAmountContributed = this.billData.billAmount;
        this.billCreationDate = this.billData.createdAt;
        this.payerName = this.billData.payerName;
        this.expireDate = this.billData.expireDate;
        this.pmtOptName = this.billData.pmtOptName;

        this.invoiceCurrency = this.billData.currency;
        this.payableBanks = 'PBZ';

        this.hidePrintBtn = false;
        this.hideMultipleBillPrint = true;
      } catch (error) {
        this.hidePrintBtn = true;
        this.toastr.error('Request could not fulfilled.')
        console.log(error);
      }
    } else {
      try {
        this.billData = JSON.parse(this.securityService.decryptString(sessionStorage.getItem(AppSettings.bulkBillerKey)));
        this.hideQrCode = false;
        this.payableBanks = 'PBZ';
        this.hidePrintBtn = false;
        this.hideMultipleBillPrint = false;
      } catch (error) {
        this.hidePrintBtn = true;
        this.toastr.error('Request could not fulfilled.')
        console.log(error);
      }
    }
  }

  printInvoiceReceipt() {
    this.isInvoicePrinted = true;
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <link rel="preconnect" href="https://fonts.googleapis.com">
           <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
           <link href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&display=swap" rel="stylesheet">
           <style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
            font-family: 'Comfortaa', sans-serif;
          }
          .table td{
            border-top: none !important;
            font-family: 'Comfortaa', sans-serif;
          }
          p{
            font-family: 'Comfortaa', sans-serif;
          }
          .space{
            display: flex;
            justify-content: space-between;
            // border: 1px dotted #a91c1c;
            padding: 1rem 0;
          }
          .btn_control_block{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
          }
          .logo__block{
          /* border-bottom: 1px dotted #a91c1c; */
          border-bottom: 1px dotted #a91c1c;
          }
          .logo__block p, .footer, p {
            color: #a91c1c;
          }
          .invoice__head__child_contacts table tbody tr td{
          font-style: italic;
          }
          .invoice{
            background-color: #fff;
            padding: 2rem;
            width: fit-content;
            max-height: 100%;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
          }
          .invoice__body table tr th{
            background: #a91c1c;
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            background-color: rgba(136 ,136 ,136, .1);
            border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block, .footer{
              text-align: center;
          }
          .full_table{
            width: 100%;
          }

          table tbody tr td{
          padding: .5rem;
          }

          @media (max-width: 576px) {
            .btn_control_block{
              display: flex;
              flex-direction: row;
            }
           }

          @media print {
            .page-break  { display:block; page-break-before:always; }
          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            font-size: 17px;
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            bottom: 0;
            width: 100%;
            text-align: center;
          }
          .invoice{
            background-color: transparent;
          }

          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
    sessionStorage.removeItem(AppSettings.billDetailsKey);
  }

}
