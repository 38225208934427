import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthService } from '../../services';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  roleName: string = '';
  @Input()
  menuItems: any;
  endpointcall:string = 'roles/get';
  data = {
    id: 0
  };

  @Input()
  menuMode: string;
  user_name: string;
  constructor(private authService:AuthService, private utilityService: UtilitiesService) {

      let user = this.authService.getUserDetails();
      this.user_name = user.first_name+' '+user.middle_name;
  }
}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
