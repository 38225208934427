import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent extends SharedClassComponent implements OnInit  {
  now: Date = new Date();
  billCreateForm: FormGroup;
  maxLength = 500;
  amount = 0;
  selectedCurrency = 'TZS';
  endpoint = 'bills/create';
  currencyDts = [
    {
      id: 'TZS',
      text: 'TZS'
    },
    {
      id: 'USD',
      text: 'USD'
    },
  ];
  paymentTypeDts = [
    {
      id: 'EXACT',
      text: 'EXACT'
    },
    {
      id: 'FULL',
      text: 'FULL'
    },
    {
      id: 'PARTIAL',
      text: 'PARTIAL'
    },
    {
      id: 'INFINITY',
      text: 'INFINITY'
    },
    {
      id: 'LIMITED',
      text: 'LIMITED'
    },
  ];
  revenueDataSource = this.authService.getCategoriesDetails();
  billItemsDatasource = [];

  institutionName = this.authService.getUserDetails().username;
  institutionCode = this.authService.getUserDetails().spId;

  collectionData =
  [{
    month: 'Jan',
    banks: 0,
  }, {
    month: 'Feb',
    banks: 0,
  }, {
    month: 'Mar',
    banks: 0,
  }, {
    month: 'Apr',
    banks: 0,
  }, {
    month: 'May',
    banks: 0,
  },
  {
    month: 'Jun',
    banks: 0,
  },
  {
    month: 'Jul',
    banks: 0,
  },
  {
    month: 'Aug',
    banks: 0,
  },
  {
    month: 'Sep',
    banks: 0,
  },
  {
    month: 'Oct',
    banks: 0,
  },
  {
    month: 'Nov',
    banks: 0,
  },
  {
    month: 'Dec',
    banks: 0,
  }
  ];

  cleared_bills = [];
  paymentsDataSource = [];
  pendingPayments = [];
  generatedBills = [];

  yearlyReportData = [];

  totalInvoiceIssued = 0;

  tzsInvoiceCount = 0;
  totalAmountInTzs = 0;

  usdInvoiceCount = 0;
  totalAmountInUsd = 0;

  totalInvoiceCleared = 0;

  tzsInvoiceCountCleared = 0;
  totalAmountInTzsCleared = 0;

  usdInvoiceCountCleared = 0;
  totalAmountInUsdCleared = 0;

  totalInvoicePending = 0;

  tzsInvoiceCountPending = 0;
  totalAmountInTzsPending = 0;

  usdInvoiceCountPending = 0;
  totalAmountInUsdPending = 0;

  currentYear = new Date().getFullYear();

  chartTitle = '';
  ngOnInit() {
    this.chartTitle = `Yearly Payment Report (${this.currentYear})`;
    this.getBillSummaryData();
    this.getYearlyPaymentReport();
    this.appInfo.setTitle('Home');
    this.billCreateForm = new FormGroup({
      payerNumber: new FormControl('', Validators.compose([])),
      payerName: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([])),
      billExpireDate: new FormControl(this.now, Validators.compose([])),
      phone: new FormControl('', Validators.compose([])),
      currency: new FormControl(this.currencyDts[0].id, Validators.compose([Validators.required])),
      paymentType: new FormControl(this.paymentTypeDts[0].id, Validators.compose([Validators.required])),
      exchangeRate: new FormControl(1, Validators.compose([])),
      billDescription: new FormControl('', Validators.compose([Validators.required])),
      revenueSource: new FormControl(null, Validators.compose([Validators.required])),

      institutionName: new FormControl(this.institutionName, Validators.compose([Validators.required])),
      institutionCode: new FormControl(this.institutionCode, Validators.compose([Validators.required])),

      billAmount: new FormControl(0, Validators.compose([Validators.required])),

    });
    this.getDailyBill();
    this.observer.observe(['(max-width: 1400px)', '(max-height: 800px)']).subscribe(result => {
      const isMatched = Object.values(result.breakpoints);
      if (isMatched[0] && isMatched[1]) {
          this.tabPanelHeight = 59;
        } else if ((window.innerWidth >= 1500 && window.innerWidth <= 1600) && window.innerHeight <= 900 ) {
          this.tabPanelHeight = 67;
        } else if (window.innerHeight <= 800 ) {
          this.tabPanelHeight = 62;
        } else if (window.innerWidth >= 2000 ) {
          this.tabPanelHeight = 75;
        } else if (window.innerWidth >= 1900 ) {
          this.tabPanelHeight = 73;
        } else {
          this.tabPanelHeight = 73;
        }
    });
  }

  clearedBillDatasource = [];
  pendingBillDatasource = [];
  allGeneratedBillDatasource = [];

  onFormReset() {
    this.amount = 0;
    this.billCreateForm.reset();
    this.billCreateForm.get('institutionName').patchValue(this.institutionName);
    this.billCreateForm.get('institutionCode').patchValue(this.institutionCode);
  }

  onPointClick(e) {
    e.target.select();
}

  getAllClearedBills() {
    this.spinner.show();
    const data = {
      "requestType": "BILLS_LIST",
      "spId": this.authService.getUserDetails().spId
    };
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();

        if (res.statusId == 2000) {

          this.clearedBillDatasource = [];
          this.pendingBillDatasource = [];
          this.allGeneratedBillDatasource = [];

          for (const iterator of res.data.validBills) {
            if (this.isCurrentDate(iterator.createdAt)) {
              this.pendingBillDatasource.push(iterator);
              this.allGeneratedBillDatasource.push(iterator);
            }
          }
          for (const iterator of res.data.clearedBills) {
            if (this.isCurrentDate(iterator.createdAt)) {
              this.clearedBillDatasource.push(iterator);
              this.allGeneratedBillDatasource.push(iterator);
            }
          }
        } else {
          this.toastr.error(res.statusMessage);
        }

        this.spinner.hide();
        this.toastr.info('Data has finished loading', 'Process Completed');
      },
      error => {
        this.spinner.hide();
        console.error(error);
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  getDailyBill() {
    const data = {
      requestType: 'DAILY_BILLS_REPORT',
      date: this.getCurrentDate(),
      "spId": this.authService.getUserDetails().spId
    }
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const serverResponse = res.json();
      if (serverResponse.statusId == 2000) {

        this.clearedBillDatasource = [];
        this.pendingBillDatasource = [];
        this.allGeneratedBillDatasource = [];

        for (const iterator of serverResponse.data.dailyBills) {
          this.allGeneratedBillDatasource.push(iterator);
          if (iterator.billStatus == 1 && iterator.billValidity == 0) {
            this.clearedBillDatasource.push(iterator);
          }
          if ((iterator.billStatus == 1 && iterator.billValidity == 1) || (iterator.billStatus == 0 && iterator.billValidity == 0)) {
            this.pendingBillDatasource.push(iterator);
          }
        }
      } else {
        this.toastr.error(serverResponse.statusMessage);
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err, 'Request Failed');
      this.spinner.hide();
    });
  }

  createBill() {
    if (this.billCreateForm.invalid) {
      this.toastr.error('Bill can not be created, required fields are empty');
      return;
    }

    if (this.billItemsDatasource.length < 1) {
      this.toastr.error('Bill can not be created, Bill items are required');
      return;
    }

    const billAmt = +this.billCreateForm.get('billAmount').value;

    if (billAmt <= 0) {
      this.toastr.error('Billed amount can not be less or equal to zero.');
      return;
    }

    let totalBillItemsAmount = 0;
    for (const iterator of this.billItemsDatasource) {

      if (iterator.itemAmount === null || iterator.itemAmount === undefined) {
      this.toastr.error('Bill can not be created, Bill item amount can not be empty');
      return;
      }

      if (iterator.itemDesc === null || iterator.itemDesc === undefined || `${iterator.itemDesc === null}`.trim() == '') {
      this.toastr.error('Bill can not be created, Bill item description can not be empty');
      return;
      }

      totalBillItemsAmount += iterator.itemAmount;
    }

    if (totalBillItemsAmount != billAmt) {
      this.toastr.error('Bill can not be created, Bill items amount is not equal to total billed amount');
      return;
    }

    if (this.billCreateForm.get('billExpireDate').value == null) {
      const dateString =  new Date().getFullYear() + "-"+  ("0"+(new Date().getMonth()+1)).slice(-2) + "-" +("0" + new Date().getDate()).slice(-2);
      this.billCreateForm.get('billExpireDate').patchValue(dateString);
    } else {
      const dateString =  new Date(this.billCreateForm.get('billExpireDate').value).getFullYear() + "-"+  ("0"+(new Date(this.billCreateForm.get('billExpireDate').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.billCreateForm.get('billExpireDate').value).getDate()).slice(-2);
      this.billCreateForm.get('billExpireDate').patchValue(dateString);
    }


    // requestType : "OTHERS_BILL_GENERATE",
    // billDescription: this.billCreateForm.get('billDescription').value,
    // payerName: this.billCreateForm.get('payerName').value,
    // mobileNo: this.billCreateForm.get('phone').value,
    // email: this.billCreateForm.get('email').value,
    // invoiceTypeCode: this.billCreateForm.get('revenueSource').value,
    // currency: this.billCreateForm.get('currency').value,
    // paymentOption: 'EXACT',
    // billExpireDate: '2071-02-03 11:48:59.353',
    // billItems: this.billItemsDatasource,

    const maximum = 99999999;
    const minimum = 9999999;

    const rawRequest = {
        "amount": billAmt,
        "description": this.billCreateForm.get('billDescription').value,
        "spId": this.institutionCode,
        "spBillId": `${Math.floor(Math.random() * (maximum - minimum + 1)) + minimum}`,
        "categoryId": this.billCreateForm.get('revenueSource').value,
        "expireDate": this.billCreateForm.get('billExpireDate').value,
        "currency": this.billCreateForm.get('currency').value,
        "payerId": `${Math.floor(Math.random() * (maximum - minimum + 1)) + minimum}`,
        "payerMobile": this.billCreateForm.get('phone').value,
        "payerEmail": this.billCreateForm.get('email').value,
        "payerName": this.billCreateForm.get('payerName').value,
        "payerTelephone": this.billCreateForm.get('phone').value,
        "paymentOption": this.billCreateForm.get('paymentType').value
    }
    // return;
    this.spinner.show();

    // setTimeout(() => {
    //   this.toastr.success('Bill is created successfully, you\'ll be notified once control number is generated.');
    //   this.spinner.hide();
    //   this.onFormReset();
    // }, 4000);

    this.utilities.postServiceCall(rawRequest, this.endpoint).subscribe(res => {
      const servRes = res.json();
      this.spinner.hide();
      if (servRes.statusId == 2000) {
        this.toastr.success('Bill is created successfully.');
        sessionStorage.removeItem(AppSettings.billDetailsKey);
        sessionStorage.removeItem(AppSettings.billItemsKey);
        sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(servRes.data));
        sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
        this.router.navigate(['/bill-payment-form']);
        this.onFormReset();
      } else {
        this.toastr.error(servRes.statusMessage, 'Request Failed');
      }
    }, err => {
      this.toastr.error('Something went wrong, failed to create the bill.');
    });
  }

  getBillSummaryData() {
    const data = {
          "requestType": "BILLS_SUMMARY",
          "spId": this.authService.getUserDetails().spId
    };
      this.utilities.postServiceCall(data).subscribe(res => {
        this.spinner.hide();
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
     
          this.totalInvoiceIssued = srvRes.data.allBills.totalCount;

          for (const iterator of srvRes.data.allBills.summary) {
            if (iterator.currency == 'TZS') {
              this.tzsInvoiceCount = iterator.billsCount;
              this.totalAmountInTzs = iterator.totalAmount;
            }

            if (iterator.currency == 'USD') {
              this.usdInvoiceCount = iterator.billsCount;
              this.totalAmountInUsd = iterator.totalAmount;
            }

          }

          this.totalInvoiceCleared = srvRes.data.clearedBills.totalCount;

          for (const iterator of srvRes.data.clearedBills.summary) {
            if (iterator.currency == 'TZS') {
              this.tzsInvoiceCountCleared = iterator.billsCount;
              this.totalAmountInTzsCleared = iterator.totalAmount;
            }

            if (iterator.currency == 'USD') {
              this.usdInvoiceCountCleared = iterator.billsCount;
              this.totalAmountInUsdCleared = iterator.totalAmount;
            }

          }

          this.totalInvoicePending = srvRes.data.validBills.totalCount;

          for (const iterator of srvRes.data.validBills.summary) {
            if (iterator.currency == 'TZS') {
              this.tzsInvoiceCountPending = iterator.billsCount;
              this.totalAmountInTzsPending = iterator.totalAmount;
            }

            if (iterator.currency == 'USD') {
              this.usdInvoiceCountPending = iterator.billsCount;
              this.totalAmountInUsdPending = iterator.totalAmount;
            }

          }
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastr.error(error);
      });
  }

  getYearlyPaymentReport() {
    const data = {
      "requestType": "YEARLY_PAYMENT_REPORT",
      "year": this.currentYear,
      "spId": this.authService.getUserDetails().spId
    };

    this.utilities.postServiceCall(data).subscribe(res => {
      this.spinner.hide();
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.yearlyReportData = [];

        for (const iterator of srvRes.data) {
          this.yearlyReportData.push({
            month: iterator.monthName,
            tzs: iterator.collection[0] === undefined ? 0 : iterator.collection[0].totalAmount,
            usd: iterator.collection[1] === undefined ? 0 : iterator.collection[1].totalAmount
          });
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error);
    });
  }

  isCurrentDate(date): boolean {
    const valueDate = `${date}`.substring(0,10);
    const currentDate = this.now.getFullYear() + "-" + ("0" + (this.now.getMonth() + 1)).slice(-2) + "-" + ("0" + this.now.getDate()).slice(-2);
    if (valueDate == currentDate) {
      return true;
    } else {
      return false;
    }
    // return true;
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.getDailyBill.bind(this)
      }
    });

  }
}
