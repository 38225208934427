import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-incoming-tiss',
  templateUrl: './incoming-tiss.component.html',
  styleUrls: ['./incoming-tiss.component.scss']
})
export class IncomingTissComponent extends SharedClassComponent implements OnInit {

  title = "Incoming TISS Report";

  tab_paneldata: any = [
    {
    ID: 1,
    icon: 'fa fa-arrow-circle-o-left',
    name: 'Incoming TISS Report',
  }
];

  ngOnInit() {
    // this.rtgsReports('incoming-tiss');
    this.rtgsReportsIn();
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }

  rtgsReportsIn(){
    const endpoint = 'incoming-tiss';
    this.spinner.show();
    this.utilities.rtgsPostServiceCall(endpoint).subscribe(res => {
      const responseData = res.json();
        this.rtgsDataSet = responseData.data;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong while process the request.');
      console.log(err);
    });
  }

  savePDF(e) {
    const doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text(e.message, 5, 10);
    doc.save(`${e.fileName}`.replace('.txt','')+'.pdf');
  }


  onPrintBillreceipt(e) {
    let popUpWindow;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
   // popupWinindow.document.open();
    popUpWindow.document.write(
     `<html>
     <head>
     <link rel="stylesheet" type="text/css" href="style.css" />
     <style>
     @media print {
      @page {
        size: A4;
        margin: 0mm;
      }

      html, body {
        width: 1024px;
      }

      body {
        margin: 0 auto;
        line-height: 1em;
        word-spacing:2px;
        letter-spacing:0.2px;
        font: 20px "Times New Roman", Times, serif;
        color:black;
        width: 100%;
        float: none;
        padding: 2rem;
      }


      /* avoid page-breaks inside a listingContainer*/
      .listingContainer{
        page-break-inside: avoid;
      }
      h1 {
          font: 28px "Times New Roman", Times, serif;
        }

        h2 {
          font: 24px "Times New Roman", Times, serif;
        }

        h3 {
          font: 20px "Times New Roman", Times, serif;
        }

        / Improve colour contrast of links /
        a:link, a:visited {
          color: #781351
        }

        / URL /
        a:link, a:visited {
          background: transparent;
          color:#333;
          text-decoration:none;
        }

        a[href]:after {
          content: "" !important;
        }
        a[href^="http://"] {
          color:#000;
        }
      }
     </style>
     </head>
     <body onload="window.print()"><pre><code> ${e.message} </code></pre></html>`
     );
    popUpWindow.document.close();
    this.previewBillsWin = false;
  }

}
