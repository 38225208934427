import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-expired-bill-report',
  templateUrl: './expired-bill-report.component.html',
  styleUrls: ['./expired-bill-report.component.scss']
})
export class ExpiredBillReportComponent extends SharedClassComponent implements OnInit {

  expiredBillDatasource = [];
  title = 'Expired Bills Report';
  showBillDialog = false;
  dialogTitle = 'Bill Items';
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.getAllExpiredBills();
  }

  getAllExpiredBills() {
    this.spinner.show();
    const data = {
      requestType: 'EXPIRED_BILLS_REPORT'
    };
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        if (res.statusId == 2000) {
          this.expiredBillDatasource = res.data.expiredBills;
          this.toastr.info('Data has finished loading', 'Process Completed');
        } else {
          this.toastr.error(res.statusMessage);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  previewExpiredBill(e) {

  }


  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }

}
