import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { ModalDialogService } from 'ngx-modal-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends SharedClassComponent implements OnInit {

  //variables defination
  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = 'Service Provider Users';
  model_name: string = 'sp/users';
  authorizeUserEndpointCall = 'users/authorize';
  resetUserEndPointCall = 'users/reset';

  ngOnInit() {
      // sets the current page title in index.html title tag
    this.appInfo.setTitle('Service Provider Users');

    this.paramwinfrm =  new FormGroup({
      requestType: new FormControl('SP_USER_CREATE', Validators.compose([])),
      username: new FormControl('', Validators.compose([Validators.required])),
      firstName: new FormControl('', Validators.compose([Validators.required])),
      middleName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      usergroupId: new FormControl('', Validators.compose([Validators.required])),
      spId: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      telephone: new FormControl('', Validators.compose([])),
      mobile: new FormControl('', Validators.compose([Validators.required])),
      id: new FormControl('', Validators.compose([])),
      userId: new FormControl('', Validators.compose([])),
      status_id: new FormControl('', Validators.compose([])),
      status: new FormControl('', Validators.compose([]))
    });

    this.getServiceProviderUserList();
    this.getServiceProviders();
    this.getUserGroups();

    this.observerCall();

  }
  refreshDataGrid() {
    this.getServiceProviderUserList();
    this.getServiceProviders();
    this.getUserGroups();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New User',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewParameter.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.refreshDataGrid.bind(this)
      }
    });

  }
funcpopWidth(percentage_width) {
    if (window.innerWidth > 800){
      return  window.innerWidth * percentage_width / 100;
    }
    else{
      return  window.innerWidth - 50;
    }

  }



}
