import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent extends SharedClassComponent implements OnInit {

  title:string = 'Accounts';
  model_name:string = 'accounts';

  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      acc_number: new FormControl('', Validators.compose([Validators.required])),
      number: new FormControl('', Validators.compose([])),
      sp_id: new FormControl('', Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      account_type_id: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([Validators.required])),
      branch: new FormControl('', Validators.compose([])),
      created_by: new FormControl('', Validators.compose([])),
      created_at: new FormControl('', Validators.compose([])),
      modified_by: new FormControl('', Validators.compose([])),
      modified_at: new FormControl('', Validators.compose([])),
      status: new FormControl('', Validators.compose([])),
      account_id: new FormControl('', Validators.compose([]))
    });
    this.onGetBranchesParamsdetails('branches', this.branchesDta);
    this.onGetAccountTypesParamsdetails('account/types', this.accountTypesData);
    this.onGetCurrencyParamsdetails('currency/denominations', this.currencyData);
    this.onGetServiceProvidersList('service/providers');
    this.onGetParamsdetails();
    // Controls the datagrid height and max rows to display
    this.observerCall();
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
  }

}
