import { Component, OnInit, NgModule, Input } from '@angular/core';
import {
  SideNavigationMenuModule,
  HeaderModule
} from '../../shared/components';
import notify from 'devextreme/ui/notify';

import { ScreenService, AuthService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';

import { navigation } from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {
  menuItems: any; // navigation;
  selectedRoute = '';
  data = {
    setting_id: '1008'
  };
  getIdleTimeoutByName = {
    setting_name: 'IDLE_TIMEOUT_IN_SECONDS'
  };
  getSettingsByNameEndpoint = 'settings/name';
  endPoint = 'settings/get';
  idleTimeout: number;

  menuOpened: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  user: any;
  role_id: number;
  constructor(
    private bnIdle: BnNgIdleService,
    private screen: ScreenService,
    private router: Router,
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public authService: AuthService
  ) {
    const isLoginForm = router.url === '/login';
    (this.user = this.authService.getUserDetails()),
      // (this.role_id = this.user.role_id);
    this.onUserRolesNavigationssdetails();

    // this.utilities.postServiceCall(this.getIdleTimeoutByName, this.getSettingsByNameEndpoint).subscribe(res => {
    //   const response = res.json();
    //   this.idleTimeout = response.data.value;
    //   if (!isLoginForm) {
    //     this.bnIdle.startWatching(this.idleTimeout).subscribe(result => {
    //       if (result) {
    //         // this.authService.logOut();
    //         this.bnIdle.stopTimer();
    //         // notify('You have been logged out due to inactivity.', 'info', 600000);
    //       }
    //     });
    //   }
    // });
  }

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }
  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 0;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }
  // load the navigation details

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node.selected) {
        pointerEvent.preventDefault();
      } else {
        this.router.navigate([path]);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }
  onUserRolesNavigationssdetails() {
    // var data = {
    //   role_id: this.role_id
    // };

    if (AppSettings.operationMode == 1) {
      this.menuItems = [
        {
          text: 'Dashboard',
          icon: 'fa fa-th-large',
          path: 'home',
        },
        {
          text: 'User Management',
          icon: 'fa fa-user-plus',
          path: 'user-management'
        },
        {
          text: 'Service Providers',
          icon: 'fa fa-university',
          path: 'register/institution'
        },
        {
          text: 'Service Provider Accounts',
          icon: 'fa fa-credit-card-alt',
          path: 'services/providers/accounts'
        }
      ];
    } else if(AppSettings.operationMode == 2) {
      this.menuItems = [
        {
          text: 'Dashboard',
          icon: 'fa fa-th-large',
          path: 'home',
        },
        {
          text: 'Profile',
          icon: 'fa fa-user',
          path: 'profile'
        },
        {
          text: 'Bill Create',
          icon: 'fa fa-calculator',
          path: 'bill/create',
        },
        {
          text: 'Bulk Bill Create',
          icon: 'fa fa-list-ol',
          path: 'bulk/bill/create',
        },
        {
          text: 'Generated Bills',
          icon: 'fa fa-list-alt',
          path: 'generated-bills'
        },
        {
          text: 'Service Categories',
          icon: 'fa fa-random',
          path: 'sp/categories'
        },
        {
          text: 'Report',
          icon: 'fa fa-file-excel-o',
          items: [
            {
              text: 'Bills Listing',
              path: 'reports/bills/listing'
            },
            // {
            //   text: 'Bill Details',
            //   path: 'reports/bills/details'
              
            // },
            
            {
              text: 'Bills Expired',
              path: 'reports/bills/expired'
            },
            {
              text: 'Audit Trail',
              path: 'reports/audit-trail'
            }
          ]
        },
      
      ];
    } else {
      this.menuItems = [
        {
          text: 'Dashboard',
          icon: 'fa fa-th-large',
          path: 'home',
        },
        {
          text: 'Profile',
          icon: 'fa fa-user',
          path: 'profile'
        },
        {
          text: 'Bill Create',
          icon: 'fa fa-calculator',
          path: 'bill/create',
        },
        {
          text: 'Bulk Bill Create',
          icon: 'fa fa-list-ol',
          path: 'bulk/bill/create',
        },
        {
          text: 'Generated Bills',
          icon: 'fa fa-list-alt',
          path: 'generated-bills'
        },
        {
          text: 'Service Provider Accounts',
          icon: 'fa fa-credit-card-alt',
          path: 'services/providers/accounts'
        },
        {
          text: 'User Management',
          icon: 'fa fa-user-plus',
          path: 'user-management'
        },
        {
          text: 'Service Providers',
          icon: 'fa fa-university',
          path: 'register/institution'
        },
        {
          text: 'Service Categories',
          icon: 'fa fa-random',
          path: 'sp/categories'
        },
        {
          text: 'Report',
          icon: 'fa fa-file-excel-o',
          items: [
            {
              text: 'Bills Listing',
              path: 'reports/bills/listing'
            },
            // {
            //   text: 'Bill Details',
            //   path: 'reports/bills/details'
            // },
            {
              text: 'Bills Expired',
              path: 'reports/bills/expired'
            },
            {
              text: 'Audit Trail',
              path: 'reports/audit-trail'
            }
          ]
        },
       
      ];
    }

    // this.menuItems = navigation;
    // this.utilities.postServiceCall(data, 'role/navigations').subscribe(
    //   response => {
    //     let data = response.json();
    //     if (data.data) {
    //       this.menuItems = data.data;
    //     } else {
    //       this.menuItems = [
    //         {
    //           text: 'Dashboard',
    //           items: [
    //             {
    //               text: 'Bills Validation',
    //               path: 'bill-validation'
    //             }
    //           ]
    //         }
    //       ];
    //     }
    //   },
    //   error => {
    //     this.spinner.hide();
    //   }
    // );
  }
  replaceKey($subject, $newKey, $oldKey) {}
  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxScrollViewModule,
    CommonModule
  ],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent]
})
export class SideNavOuterToolbarModule {}
