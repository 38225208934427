import { Component, OnInit } from '@angular/core';
import csv2json from 'csvjson-csv2json/csv2json';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import XLSX from 'xlsx-style/dist/xlsx';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from '../app-settings';

@Component({
  selector: 'app-bulk-bill-create',
  templateUrl: './bulk-bill-create.component.html',
  styleUrls: ['./bulk-bill-create.component.scss']
})
export class BulkBillCreateComponent extends SharedClassComponent implements OnInit {

  // NEW VARIABLES

  showLoadingDialog = false;
  prefixText = 'Correct Name is: ';
  hideInfoAlert = true;
  invoiceForm: FormGroup;
  lastContributionDatasetForm: FormGroup;
  invoiceCreateEndPoint = 'portal/request';
  selectedRowIndex = -1;
  isFileValid = false;
  yearPattern = '^(19|20)\\d{2}$';
  monthPattern = '/^(0[1-9]|1[012])$/';
  employerContributionAmt = 0;
  memberContributionAmt = 0;
  currrentEmployerNumber: any;
  userGroup: any;
  isMultipleContributionMode = false;
  value: any[] = [];
  title = 'Create Invoice For Employees Contributions';
  loadExistingListEndpointCall = 'employer/employees';
  createInvoiceEndpointCall = 'invoice/create';
  validateMemberNumberEndPoint = 'users/verify';
  validateEmployerNumberEndPoint = '';
  uploadFailedMessage = '';
  openFileUploadDialog = false;
  employeeUploadListDataSource: any = [];
  hideEmployeeListDataGrid = true;
  hideEmployeeFetchedDataListDataGrid = true;
  hideEmployeeListManualEntrance = true;
  hideEmployeeUploadForm = true;
  hideDataUploadControls = false;
  hideInitialInvoiceDetails = false;
  isUploadFileMethodSelected = false;
  hideBackButton = true;
  steps = 0;
  months = [];
  years: any;
  curDate = new Date();
  userDetails: any;
  currentMonth = this.curDate.getMonth() +  1;
  currentYear = this.curDate.getFullYear();

  invoiceError: Error = null;
  buttonText = 'Create Invoice';
  iconText = 'save';
  showYearAndMonthColumn = true;
  batchContributionData = [];
  isContributionBatchRequest = false;
  isAddNewContributionToAnyExistingInvoice = false;
  invoiceIdToAddContribution;
  disableContributionType = false;
  lastContributionsDataSet = [];

  selectedContributionMonths = [];
  selectedContributionYear;
  selectedContributionType;
  descriptionString = '';
  monthInText = [];
  hideLoadMembersButton = true;
  showAlertDialog = false;
  showWarningDialog = false;
  alertReason = '';


  lastContributionTotalAmount = 0;
  currentContributionTotalAmount = 0;
  continueWithWarning = false;

  customerDetails = [];
  uncontributedPeriod = [];
  yearsMonths = [];

  thresholdAmount = 1;

  // END - NEW VARIABLES


  billCreateForm: FormGroup;
  currencyDts = [
    {
      id: 'TZS',
      text: 'TZS'
    },
    {
      id: 'USD',
      text: 'USD'
    },
  ];
  paymentTypeDts = [
    {
      id: 'EXACT',
      text: 'EXACT'
    },
    {
      id: 'FULL',
      text: 'FULL'
    },
    {
      id: 'PARTIAL',
      text: 'PARTIAL'
    },
    {
      id: 'INFINITY',
      text: 'INFINITY'
    },
    {
      id: 'LIMITED',
      text: 'LIMITED'
    },
  ];
  revenueDataSource = [];
  institutionName = this.authService.getUserDetails().username;
  institutionCode = this.authService.getUserDetails().spId;
  billerBulkDatasource = [];
  createdBillDatasource = [];
  showBillPendingAlert = false;
  ngOnInit() {
    this.appInfo.setTitle('Bulk Bill Create');
    this.billCreateForm = new FormGroup({
      catName: new FormControl(null, Validators.compose([Validators.required])),
      currencyDts: new FormControl(null, Validators.compose([Validators.required])),
      paymentTypeDts: new FormControl(null, Validators.compose([Validators.required]))
    });
    this.getCategoryBySp();
  }

  resetContributionPage() {
    this.billCreateForm.reset();
    this.billerBulkDatasource = [];
  }

  postInvoice() {

    if (this.billerBulkDatasource.length < 1) {
      notify('No bill\'s data has been provided', 'error', 6000);
      return;
    }

    if(this.billerBulkDatasource[0].amount == 0 || this.billerBulkDatasource[0].amount == null || this.billerBulkDatasource[0].amount == undefined) {
      notify('Payer can not be less or equal to zero', 'error', 6000);
      return;
    }

    if (this.billCreateForm.invalid) {
      notify('Please select bill currency, bill category and bill payment option!', 'error', 6000);
      return;
    }

    if (this.billerBulkDatasource.length <= 0) {
      notify('Members Contribution data required!', 'error', 6000);
      return;
    }

    this.spinner.show();

    const requestData = {
      requestType: "BILLS_CREATE_BULK",
      details: this.billerBulkDatasource
    };

    this.utilities.postServiceCall(requestData).subscribe(res => {
        const serverResponse = res.json();
        this.spinner.hide();

        if (serverResponse.statusId == 2000 && serverResponse.data.length > 0) {

          const bulkData = [];
          this.toastr.success('Bills created successfully.');
          this.showBillPendingAlert = true;

          this.createdBillDatasource = [];
          for (const iterator of serverResponse.data) {
            this.createdBillDatasource.push({
              payerName: iterator.payerName,
              controlNumber: iterator.controlNumber,
              billAmount: iterator.billAmount,
              currency: iterator.currency,
              expireDate: iterator.expireDate
            });
          }

          this.billCreateForm.reset();
          this.billerBulkDatasource = [];
          // this.router.navigate(['/bill-payment-form']);
        } else if (serverResponse.statusId == 2000 && serverResponse.data.length < 0) {
          this.showAlertDialog = true;
          this.alertReason = 'API has returned empty bill array.';
        } else {
          this.showAlertDialog = true;
          this.alertReason = serverResponse.message;
        }
      }, error => {
             this.spinner.hide();
             this.toastr.error('Something went wrong, please try again');
      });
  }

  openPopUp() {
    if (this.billCreateForm.get('catName').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Category first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('currencyDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Currency first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('paymentTypeDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Payment Option first to proceed.`;
      this.spinner.hide();
      return;
    }
    this.openFileUploadDialog = true;
    this.value = [];
  }
  closePopUp() {
    this.openFileUploadDialog = false;
    this.showAlertDialog = false;
  }

  onFileValueChanged(event) {
    this.billerBulkDatasource = [];
    let data = {};
    let totalContributionsAmt = 0;
    this.uploadFailedMessage = 'Loading....';
    /*
      check file type
      if it is xlsx or xls call parseXLXS() function
    */
   if (`${event.file.name}`.endsWith('.xlsx') || `${event.file.name}`.endsWith('.xls')) {
    //  this.toastr.info('Excel File Loading (xlsx or xls detected)');
    this.parseXLXS(event);
    return;
   }
   this.spinner.show();
    const reader = new FileReader();
    reader.onload = () => {
      this.spinner.show();
      this.uploadFailedMessage =
        'Converting excel data format to json format....';
      const text = reader.result;
      this.hideInfoAlert = false;
      // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
      this.showLoadingDialog = true;
      const json = csv2json(text, { parseNumbers: true });


      let amount: any;
      let counter = 0;

      for (let i = 0; i < json.length; i++) {

          if (!json[0].hasOwnProperty('PAYER_ID')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_ID header in your excelsheet on member numbers column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('PAYER_NAME')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_NAME header in your excelsheet on member names column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('AMOUNT')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include AMOUNT header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('BILL_ID')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include BILL_ID header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('DESCRIPTION')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include DESCRIPTION header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('EXPIRE_DATE')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include EXPIRE_DATE header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('PAYER_MOBILE')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_MOBILE header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('PAYER_EMAIL')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_EMAIL header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

        if (json[i].AMOUNT == null || json[i].AMOUNT == '' || json[i].AMOUNT === undefined) {
            this.showAlertDialog = true;
            this.alertReason = `AMOUNT is Missing, please update your AMOUNT in the excel sheet.`;
            this.spinner.hide();
            return;
        }

        if (json[i].PAYER_NAME == null || json[i].PAYER_NAME == '' || json[i].PAYER_NAME == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `PAYER_NAME column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }
        if (json[i].DESCRIPTION == null || json[i].DESCRIPTION == '' || json[i].DESCRIPTION == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `DESCRIPTION column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }
        if (json[i].EXPIRE_DATE == null || json[i].EXPIRE_DATE == '' || json[i].EXPIRE_DATE == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `EXPIRE_DATE column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }

        if (isNaN(json[i].AMOUNT) && json[i].AMOUNT !== undefined) {
          amount = json[i].AMOUNT.toString();
          amount = +amount.replace(/,/g, '');
        } else {
          amount = json[i].AMOUNT;
        }
        if (amount < this.thresholdAmount) {
          this.showAlertDialog = true;
          this.alertReason = `Negative amount is not allowed for amount field.`;
          this.spinner.hide();
          return;
        }

        let PAYER_MOBILE = json[i].PAYER_MOBILE === undefined ? '':`${json[i].PAYER_MOBILE}`;
        let PAYER_EMAIL = json[i].PAYER_EMAIL === undefined ? '':`${json[i].PAYER_EMAIL}`;
        let BILL_ID = json[i].BILL_ID === undefined ? '':`${json[i].BILL_ID}`;
        let PAYER_ID = json[i].PAYER_ID === undefined ? '':`${json[i].PAYER_ID}`;

        if (PAYER_MOBILE.trim() === '' || PAYER_MOBILE === null || PAYER_MOBILE === undefined) {
          PAYER_MOBILE = ""
        }
        if (PAYER_EMAIL.trim() === '' || PAYER_EMAIL === null || PAYER_EMAIL === undefined) {
          PAYER_EMAIL = ""
        }
        if (BILL_ID.trim() === '' || BILL_ID === null || BILL_ID === undefined) {
          BILL_ID = ""
        }
        if (PAYER_ID.trim() === '' || PAYER_ID === null || PAYER_ID === undefined) {
          PAYER_ID = ""
        }

        if (amount >=  this.thresholdAmount) {
          this.billerBulkDatasource.push({
            id: ++counter,
            spId: this.institutionCode,
            categoryId: this.billCreateForm.get('catName').value,
            payerId: json[i].PAYER_ID,
            currency: this.billCreateForm.get('currencyDts').value,
            payerName: json[i].PAYER_NAME,
            amount: json[i].AMOUNT,
            spBillId: json[i].BILL_ID,
            description: json[i].DESCRIPTION,
            expireDate: json[i].EXPIRE_DATE,
            payerMobile: json[i].PAYER_MOBILE,
            payerEmail: json[i].PAYER_EMAIL,
            payerTelephone: '',
            paymentOption: this.billCreateForm.get('paymentTypeDts').value,
          });

        }
        this.uploadFailedMessage = 'Conversion completed....';
        }
      this.spinner.hide();
      this.showLoadingDialog = true;
    };
    reader.onloadend = () => {
      this.spinner.hide();
      this.showLoadingDialog = false;
      alert('Data upload completed');
      this.uploadFailedMessage = 'Data uploaded successfully....';
      this.openFileUploadDialog = false;
    };
    reader.readAsText(event.file);
  }

  selectedChanged(e) {
    // this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onRowInserting(e) {
    if (this.billCreateForm.get('catName').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Category first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('currencyDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Currency first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('paymentTypeDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Payment Option first to proceed.`;
      this.spinner.hide();
      return;
    }
    e.data.id = this.billerBulkDatasource.length + 1
    e.data.spId = this.institutionCode;
    e.data.categoryId = this.billCreateForm.get('catName').value;
    e.data.currency = this.billCreateForm.get('currencyDts').value;
    e.data.paymentOption = this.billCreateForm.get('paymentTypeDts').value;
  }
  onRowInserted(e) {
    if (this.billCreateForm.get('catName').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Category first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('currencyDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Currency first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('paymentTypeDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Payment Option first to proceed.`;
      this.spinner.hide();
      return;
    }
    // e.data.id = this.billerBulkDatasource.length + 1
    e.data.spId = this.institutionCode;
    e.data.categoryId = this.billCreateForm.get('catName').value;
    e.data.currency = this.billCreateForm.get('currencyDts').value;
    e.data.paymentOption = this.billCreateForm.get('paymentTypeDts').value;
  }

  onRowUpdated(e) {
    if (this.billCreateForm.get('catName').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Category first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('currencyDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Bill Currency first to proceed.`;
      this.spinner.hide();
      return;
    }
    if (this.billCreateForm.get('paymentTypeDts').invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please select Payment Option first to proceed.`;
      this.spinner.hide();
      return;
    }
    e.data.spId = this.institutionCode;
    e.data.categoryId = this.billCreateForm.get('catName').value;
    e.data.currency = this.billCreateForm.get('currencyDts').value;
    e.data.paymentOption = this.billCreateForm.get('paymentTypeDts').value;
  }

  onEditingStart(e) {
    // e.data.employerNumber = this.currrentEmployerNumber;
  }

  parseXLXS = (event) => {
    this.billerBulkDatasource = [];
    let totalContributionsAmt = 0;
    this.uploadFailedMessage = 'Loading....';
    let reader = new FileReader();

    reader.onload = () => {
      let data = reader.result;
      let workbook = XLSX.read(data, {
        type: 'binary'
      });
      this.hideInfoAlert = false;
      // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
      workbook.SheetNames.forEach((sheetName) => {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        let json = XL_row_object;
        this.uploadFailedMessage =
        'Converting excel data format to json format....';
      this.spinner.show();

      let amount: any;
      let counter = 0;

      for (let i = 0; i < json.length; i++) {

          if (!json[0].hasOwnProperty('PAYER_ID')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_ID header in your excelsheet on member numbers column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('PAYER_NAME')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_NAME header in your excelsheet on member names column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('AMOUNT')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include AMOUNT header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('BILL_ID')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include BILL_ID header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('DESCRIPTION')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include DESCRIPTION header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('EXPIRE_DATE')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include EXPIRE_DATE header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('PAYER_MOBILE')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_MOBILE header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }
          if (!json[0].hasOwnProperty('PAYER_EMAIL')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include PAYER_EMAIL header in your excelsheet on members salaries column.`;
              this.spinner.hide();
              return;
          }

        if (json[i].AMOUNT == null || json[i].AMOUNT == '' || json[i].AMOUNT === undefined) {
            this.showAlertDialog = true;
            this.alertReason = `AMOUNT is Missing, please update your AMOUNT in the excel sheet.`;
            this.spinner.hide();
            return;
        }

        if (json[i].PAYER_NAME == null || json[i].PAYER_NAME == '' || json[i].PAYER_NAME == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `PAYER_NAME column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }
        if (json[i].DESCRIPTION == null || json[i].DESCRIPTION == '' || json[i].DESCRIPTION == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `DESCRIPTION column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }
        if (json[i].EXPIRE_DATE == null || json[i].EXPIRE_DATE == '' || json[i].EXPIRE_DATE == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `EXPIRE_DATE column in your excelsheet is empty, please include it in your excelsheet.`;
            this.spinner.hide();
            return;
        }

        if (isNaN(json[i].AMOUNT) && json[i].AMOUNT !== undefined) {
          amount = json[i].AMOUNT.toString();
          amount = +amount.replace(/,/g, '');
        } else {
          amount = json[i].AMOUNT;
        }
        if (amount < this.thresholdAmount) {
          this.showAlertDialog = true;
          this.alertReason = `Negative amount is not allowed for amount field.`;
          this.spinner.hide();
          return;
        }

        let PAYER_MOBILE = json[i].PAYER_MOBILE === undefined ? '':`${json[i].PAYER_MOBILE}`;
        let PAYER_EMAIL = json[i].PAYER_EMAIL === undefined ? '':`${json[i].PAYER_EMAIL}`;
        let BILL_ID = json[i].BILL_ID === undefined ? '':`${json[i].BILL_ID}`;
        let PAYER_ID = json[i].PAYER_ID === undefined ? '':`${json[i].PAYER_ID}`;

        if (PAYER_MOBILE.trim() === '' || PAYER_MOBILE === null || PAYER_MOBILE === undefined) {
          PAYER_MOBILE = ""
        }
        if (PAYER_EMAIL.trim() === '' || PAYER_EMAIL === null || PAYER_EMAIL === undefined) {
          PAYER_EMAIL = ""
        }
        if (BILL_ID.trim() === '' || BILL_ID === null || BILL_ID === undefined) {
          BILL_ID = ""
        }
        if (PAYER_ID.trim() === '' || PAYER_ID === null || PAYER_ID === undefined) {
          PAYER_ID = ""
        }

        if (amount >=  this.thresholdAmount) {
          this.billerBulkDatasource.push({
            id: ++counter,
            spId: this.institutionCode,
            categoryId: this.billCreateForm.get('catName').value,
            payerId: json[i].PAYER_ID,
            currency: this.billCreateForm.get('currencyDts').value,
            payerName: json[i].PAYER_NAME,
            amount: json[i].AMOUNT,
            spBillId: json[i].BILL_ID,
            description: json[i].DESCRIPTION,
            expireDate: json[i].EXPIRE_DATE,
            payerMobile: json[i].PAYER_MOBILE,
            payerEmail: json[i].PAYER_EMAIL,
            payerTelephone: '',
            paymentOption: this.billCreateForm.get('paymentTypeDts').value,
          });

        }
        this.uploadFailedMessage = 'Conversion completed....';
        }
      })
    };

    reader.onloadend = () => {
      this.spinner.hide();
      this.uploadFailedMessage = 'Data uploaded successfully....';
      this.openFileUploadDialog = false;
    };

    reader.onerror = (ex) => {
    };

    reader.readAsBinaryString(event.file);
    this.hideEmployeeUploadForm = true;
    this.hideEmployeeListDataGrid = false;
  };

  getCategoryBySp() {
    this.revenueDataSource = [];
    const data = {
      "requestType": "SP_SERVICE_CATEGORY_LIST",
      "spId": this.institutionCode
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.revenueDataSource = srvRes.data;
      }
      this.spinner.hide();
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }

}
