import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataVisualizationService {

  clearedBillsDataSource: any;
  pendingBillsDataSource: any;
  rejectedBillsDataSource: any;

  clearedBillsCounter = 0;
  pendingBillsCounter = 0;
  rejectedBillsCounter = 0;

  clearedTissTransactions = 0;
  clearedCashTransactions = 0;
  clearedTransferTransactions = 0;

  // error counter
  CbsPostingErrorCounter = 0;
  paymentAlreadyReceivedInCbsErrorCounter = 0;
  gatewayUnreachableErrorCounter = 0;
  otherReasonsCounter = 0;

   serviceProvidersList;
   serviceProvidersCounter = 0;

   serviceProviderPerTransactions = [
    {
      spCode: '',
      clearedTransactions: 0
    }
  ];


  constructor() {}

  public setClearedBillsData(datasource) {
    this.clearedBillsDataSource = datasource;
  }

  public setPendingBillsData(datasource) {
    this.pendingBillsDataSource = datasource;
  }
  public setRejectedBillsData(datasource) {
    this.rejectedBillsDataSource = datasource;
  }

  public setServiceProvidersData(datasource) {
    this.serviceProvidersList = datasource;
  }

  public setClearedBillCounter(totalNumberOfClearedBills) {
    this.clearedBillsCounter = totalNumberOfClearedBills;
  }

  public setRejectedBillCounter(totalNumberOfRejectedBills) {
    this.rejectedBillsCounter = totalNumberOfRejectedBills;
  }

  public setPendingBillCounter(totalNumberOfPendingBills) {
    this.pendingBillsCounter = totalNumberOfPendingBills;
  }

  public getClearedBillsDataSource() {
    return this.clearedBillsDataSource;
  }

  public getFailedBillDataSource() {
    return this.rejectedBillsDataSource;
  }

  public getTotalNumberOfClearedBills() {
    return this.clearedBillsCounter;
  }

  public getTotalNumberOfFailedBills() {
    return this.rejectedBillsCounter;
  }

  public setServiceProviderCounter(totalNumberOfServiceProviders) {
    this.serviceProvidersCounter = totalNumberOfServiceProviders;
  }

  public getServiceProviderCounter() {
    return  this.serviceProvidersCounter;
  }

  public getServiceProvidersDataSource() {
    return this.serviceProvidersList;
  }

  public getTotalNumberOfCBS_postingFailedReason() {
    for (let i = 0; i < this.getTotalNumberOfFailedBills(); i++) {
      if (this.getFailedBillDataSource()[i].status_code == 2031) {
        this.CbsPostingErrorCounter += 1;
      }
    }
    return this.CbsPostingErrorCounter;
  }

  public getTotalNumberOfPaymentAlreadyReceivedInCBSreason() {
    for (let i = 0; i < this.getTotalNumberOfFailedBills(); i++) {
      if (this.getFailedBillDataSource()[i].status_code == 2034) {
        this.paymentAlreadyReceivedInCbsErrorCounter += 1;
      }
    }
    return this.paymentAlreadyReceivedInCbsErrorCounter;
  }

  public getTotalNumberOfPaymentGatewayUnreachableReason() {
    for (let i = 0; i < this.getTotalNumberOfFailedBills(); i++) {
      if (this.getFailedBillDataSource()[i].status_code == 2050) {
        this.gatewayUnreachableErrorCounter += 1;
      }
    }
    return this.gatewayUnreachableErrorCounter;
  }

  public getTotalNumberOfotherReasons() {
    for (let i = 0; i < this.getTotalNumberOfFailedBills(); i++) {
      if (this.getFailedBillDataSource()[i].status_code != 2050 &&
          this.getFailedBillDataSource()[i].status_code != 2034 &&
          this.getFailedBillDataSource()[i].status_code != 2031) {
        this.otherReasonsCounter += 1;
      }
    }
    return this.otherReasonsCounter;
  }

  public getTotalNumberOfClearedCashTransactions() {
    for (let i = 0; i < this.getTotalNumberOfClearedBills(); i++) {
      if (this.getClearedBillsDataSource()[i].payment_option == 301) {
        this.clearedCashTransactions += 1;
      }
    }
    return this.clearedCashTransactions;
  }

  public getTotalNumberOfClearedTransferTransactions() {
    for (let i = 0; i < this.getTotalNumberOfClearedBills(); i++) {
      if (this.getClearedBillsDataSource()[i].payment_option == 302) {
        this.clearedTransferTransactions += 1;
      }
    }
    return this.clearedTransferTransactions;
  }

  public getTotalNumberOfClearedTissTransactions() {
    for (let i = 0; i < this.getTotalNumberOfClearedBills(); i++) {
      if (this.getClearedBillsDataSource()[i].payment_option == 303) {
        this.clearedTissTransactions += 1;
      }
    }
    return this.clearedTissTransactions;
  }

  // percentage calculations

  public getCashClearedBillsPercent() {
    return (this.getTotalNumberOfClearedCashTransactions() / this.getTotalNumberOfClearedBills()) * 100;
  }

  public getTransferClearedBillsPercent() {
    return (this.getTotalNumberOfClearedTransferTransactions() / this.getTotalNumberOfClearedBills()) * 100;
  }

  public getTissClearedBillsPercent() {
   return (this.getTotalNumberOfClearedTissTransactions() / this.getTotalNumberOfClearedBills()) * 100;
  }

  // failed bills categorized by reasons
  public getCBS_postingFailedReasonPercent() {
   return (this.getTotalNumberOfCBS_postingFailedReason() / this.getTotalNumberOfFailedBills()) * 100;
  }

  public getPaymentAlreadyReceivedInCBSreasonPercent() {
    return (this.getTotalNumberOfPaymentAlreadyReceivedInCBSreason() / this.getTotalNumberOfFailedBills()) * 100;
  }

  public getPaymentGatewayUnreachableReasonPercent() {
    return (this.getTotalNumberOfPaymentGatewayUnreachableReason() / this.getTotalNumberOfFailedBills()) * 100;
  }

  public getOtherBillsFailedReasonsPercent() {
    return (this.getTotalNumberOfotherReasons() / this.getTotalNumberOfFailedBills()) * 100;
  }

  public getTotalTransactionsPerServiceProvider() {

    for (let i = 0; i < this.getServiceProviderCounter(); i++) {
      let count = 0; // reset the counter for each word
      for (let j = 0; j < this.getTotalNumberOfClearedBills(); j++) {
        if (this.getServiceProvidersDataSource()[i].code == this.getClearedBillsDataSource()[j].sp_code) {
            let spCode = this.getClearedBillsDataSource()[j].sp_code;
            if (j < i) {
                  break; // exit the inner loop, continue with the outer one
              }
            count++;
            this.serviceProviderPerTransactions.push({
                spCode,
                clearedTransactions: count
              }
                );
        }
      }
    }
    return this.serviceProviderPerTransactions;
  }

  public getClearedBillsDataAnalysis() {
    const billsCleared = [
      {
        paymentOption: 'CASH',
        percent: this.getCashClearedBillsPercent()
      },
      {
        paymentOption: 'TRANSFER',
        percent: this.getTransferClearedBillsPercent()
      },
      {
        paymentOption: 'TISS',
        percent: this.getTissClearedBillsPercent()
      }
    ];
    return billsCleared;
  }

  public getBillsRejectionReasonsAnalysis() {
    const reasons = [
      {
        reasonMessage: 'CBS posting Failed',
        percent: this.getCBS_postingFailedReasonPercent()
      },
      {
        reasonMessage: 'Bill already received in CBS',
        percent: this.getPaymentAlreadyReceivedInCBSreasonPercent()
      },
      {
        reasonMessage: 'Payment gateway is unreachable',
        percent: this.getPaymentGatewayUnreachableReasonPercent()
      },
      {
        reasonMessage: 'Other reasons',
        percent: this.getOtherBillsFailedReasonsPercent()
      }
    ];
    return reasons;
  }

}

