  import { Component, OnInit } from '@angular/core';
  import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
  import { FormGroup, FormControl, Validators } from '@angular/forms';
  import { DxValidatorModule } from 'devextreme-angular/ui/validator';
  import notify from 'devextreme/ui/notify';
  import { AppSettings } from 'src/app/app-settings';

  @Component({
    selector: 'app-bill-validation',
    templateUrl: './bill-validation.component.html',
    styleUrls: ['./bill-validation.component.scss']
  })
  export class BillValidationComponent extends SharedClassComponent implements OnInit   {
    now: Date = new Date();
    billCreateForm: FormGroup;
    maxLength = 500;
    amount = 0;
    selectedCurrency = 'TZS';
    endpoint = 'bills/create';
    ItemForm:FormGroup
    billItems=[]
    currencyDts = [
      {
        id: 'TZS',
        text: 'TZS'
      },
      {
        id: 'USD',
        text: 'USD'
      },
    ];
    paymentTypeDts = [
      {
        id: 'EXACT',
        text: 'EXACT'
      },
      {
        id: 'FULL',
        text: 'FULL'
      },
      {
        id: 'PARTIAL',
        text: 'PARTIAL'
      },
      {
        id: 'INFINITY',
        text: 'INFINITY'
      },
      {
        id: 'LIMITED',
        text: 'LIMITED'
      },
    ];
    revenueDataSource = this.authService.getCategoriesDetails();
    billItemsDatasource = [];
    createdBillDatasource = [];

    institutionName = this.authService.getUserDetails().spName;
    institutionCode = this.authService.getUserDetails().spId;
    title = 'Create Bill';
    spCatList = [];
    showBillPendingAlert = false;
    showBillItemsDialog = false;
    cityPattern = "^[^0-9]+$";
    namePattern: any = /^[^0-9]+$/;
    phonePattern: any = /^[0-9]+$/;
    countries: string[];
    phoneRules: any = {
        X: /[0-9]/
    }
    billId = null;
    hideUpdateBillButton = true;
    editBillDatasource: any;
    accDistributionDatasource = [];

    hideBillUpdateCategory = true;

    ngOnInit() {
      this.appInfo.setTitle(this.title); // sets the current page title in index.html title tag
      this.billCreateForm = new FormGroup({
        payerNumber: new FormControl('', Validators.compose([])),
        payerName: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', Validators.compose([Validators.email])),
        billExpireDate: new FormControl(this.now, Validators.compose([])),
        phone: new FormControl('', Validators.compose([Validators.min(10), Validators.required])),
        currency: new FormControl(this.currencyDts[0].id, Validators.compose([Validators.required])),
        paymentType: new FormControl(this.paymentTypeDts[0].id, Validators.compose([Validators.required])),
        exchangeRate: new FormControl(1, Validators.compose([])), // not used
        billDescription: new FormControl('', Validators.compose([Validators.required])),
        revenueSource: new FormControl(null, Validators.compose([])), // not used
        institutionName: new FormControl({value: this.institutionName, disabled: true}, Validators.compose([])),
        institutionCode: new FormControl({value: this.institutionCode, disabled: true}, Validators.compose([])),
        billAmount: new FormControl(null, Validators.compose([])),
        spBillId: new FormControl('', Validators.compose([])),
        payerId: new FormControl('', Validators.compose([]))
      });

      this.ItemForm = new FormGroup({
        catName: new FormControl('', Validators.compose([Validators.required])),
        amount: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^[0-9]\d*$/)])),
        description: new FormControl('', Validators.compose([Validators.required])),
    
      });

      this.getCategoryBySp();

      this.route.queryParams.subscribe((params) => {
        this.billId = params.billId;

        if (sessionStorage.getItem(AppSettings.billEditKey) !== null && sessionStorage.getItem(AppSettings.billEditKey) !== undefined) {
        this.editBillDatasource =  JSON.parse(sessionStorage.getItem(AppSettings.billEditKey));
        this.hideUpdateBillButton = false;
        this.billCreateForm.patchValue(this.editBillDatasource);
        this.billCreateForm.get('payerName').patchValue(this.editBillDatasource.payerName);
        this.billCreateForm.get('email').patchValue(this.editBillDatasource.payerEmail);
        this.billCreateForm.get('billExpireDate').patchValue(this.editBillDatasource.createdAt);
        this.billCreateForm.get('phone').patchValue(this.editBillDatasource.payerMobile);
        this.billCreateForm.get('currency').patchValue(this.editBillDatasource.currency);
        this.billCreateForm.get('paymentType').patchValue(this.editBillDatasource.pmtOptName);
        this.billCreateForm.get('billDescription').patchValue(this.editBillDatasource.billDesc);
        this.billCreateForm.get('billAmount').patchValue(this.editBillDatasource.billAmount);
        this.billCreateForm.get('spBillId').patchValue(this.editBillDatasource.spBillId);
        this.billCreateForm.get('payerId').patchValue(this.editBillDatasource.payerId);
        this.billItemsDatasource = this.editBillDatasource.billItems;
        } else {
          this.hideUpdateBillButton = true;
        }

      });
    }

   

    createBill() {
       
       
      if (this.billCreateForm.invalid) {
        this.toastr.error('Bill can not be created, required fields are empty');
        console.log(this.billCreateForm);
        return;
      }

      if (this.billItems.length < 1) {
        this.toastr.error('Bill can not be created, Bill items are required');
        return;
      }

      let totalBillItemsAmount = 0;
      for (const iterator of this.billItems) {

        if (iterator.amount === null || iterator.amount === undefined) {
        this.toastr.error('Bill can not be created, Bill item amount can not be empty');
        return;
        }

        if (iterator.description === null || iterator.description === undefined || `${iterator.description === null}`.trim() == '') {
        this.toastr.error('Bill can not be created, Bill item description can not be empty');
        return;
        }
        totalBillItemsAmount += parseFloat(iterator.amount);
        //console.log(totalBillItemsAmount)
      }

      if (totalBillItemsAmount <= 0) {
        this.toastr.error('Billed amount can not be less or equal to zero.');
        return;
      }


      if (this.billCreateForm.get('billExpireDate').value == null) {
        const dateString =  new Date().getFullYear() + "-"+  ("0"+(new Date().getMonth()+1)).slice(-2) + "-" +("0" + new Date().getDate()).slice(-2);
        this.billCreateForm.get('billExpireDate').patchValue(dateString);
      } else {
        const dateString =  new Date(this.billCreateForm.get('billExpireDate').value).getFullYear() + "-"+  ("0"+(new Date(this.billCreateForm.get('billExpireDate').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.billCreateForm.get('billExpireDate').value).getDate()).slice(-2);
        this.billCreateForm.get('billExpireDate').patchValue(dateString);
      }

      const rawRequest = {
          "requestType": "BILLS_CREATE",
          billId: this.billId,
          "amount": totalBillItemsAmount,
          "description": this.billCreateForm.get('billDescription').value,
          "spId": this.institutionCode,
          "spBillId": this.billCreateForm.get('spBillId').value,
          "expireDate": this.billCreateForm.get('billExpireDate').value,
          "currency": this.billCreateForm.get('currency').value,
          "payerId": this.billCreateForm.get('payerId').value,
          "payerMobile": this.billCreateForm.get('phone').value,
          "payerEmail": this.billCreateForm.get('email').value,
          "payerName": this.billCreateForm.get('payerName').value,
          "payerTelephone": this.billCreateForm.get('phone').value,
          "paymentOption": this.billCreateForm.get('paymentType').value,
          billItems: this.billItems
      }

      if (this.hideUpdateBillButton == false) {
        rawRequest.requestType = 'BILLS_UPDATE';
      }
     
      this.spinner.show();
      this.utilities.postServiceCall(rawRequest).subscribe(res => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          this.createdBillDatasource.push(servRes.data);
          this.toastr.success('Bill is created successfully.');
          this.showBillPendingAlert = true;
          // sessionStorage.removeItem(AppSettings.billDetailsKey);
          // sessionStorage.removeItem(AppSettings.billItemsKey);
          // sessionStorage.removeItem(AppSettings.bulkBillerKey);
          // sessionStorage.removeItem(AppSettings.bulkBillerFlag);
          // sessionStorage.setItem(AppSettings.bulkBillerFlag,  '0');
          // sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(servRes.data));
          // sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
          // this.router.navigate(['/bill-payment-form']);
          this.billItems=[]
          this.billCreateForm.reset()
          this.onFormReset();
          sessionStorage.removeItem(AppSettings.billEditKey);
        } else {
          this.toastr.error(servRes.statusMessage, 'Request Failed');
        }
      }, err => {
        this.spinner.hide();
        this.toastr.error('Something went wrong, failed to create the bill.');
      });
    }

    getBillDetailsForUpdate() {

    }

    onFormReset() {
      this.amount = 0;
      this.billItemsDatasource = [];
      this.billCreateForm.reset();
      this.billCreateForm.get('institutionName').patchValue(this.institutionName);
      this.billCreateForm.get('institutionCode').patchValue(this.institutionCode);
    }


    getCategoryBySp() {
      const data = {
        "requestType": "SP_SERVICE_CATEGORY_LIST",
        "spId": this.institutionCode
      };
      this.spinner.show();
      this.utilities.postServiceCall(data).subscribe(res => {
        const srvRes = res.json();
       //console.log(srvRes)
        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error('Failed to load Service Categories', srvRes.statusMessage);
        }
        this.spinner.hide();
      }, error => {
        this.toastr.error(error);
        this.spinner.hide();
      });
    }


    addNewBillItem() {
      this.showBillItemsDialog = true;
      this.selectedCurrency = this.billCreateForm.get('currency').value;
      this.accDistributionDatasource = [];
    }

    onParamsToolBarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxButton',
          id:"button-style",
          options: {
            text: 'Add Item',
            type: 'default',
            icon: 'fa fa-plus',
            onClick: this.addNewBillItem.bind(this)
          }
        }
      );
    }


    onServiceCategoryChanged(e) {
      //console.log(e);
      this.accDistributionDatasource = [];

      if (this.billCreateForm.get('currency').invalid) {
        this.toastr.error('Please select Currency first');
        return;
      }

      let counter = 0;
      for (const iterator of this.spCatList) {
        if (iterator.catId == e.value) {
          for (const item of iterator.accDistribution) {
            if (item.currency == this.billCreateForm.get('currency').value) {
              for (const i of item.distribution) {
                this.accDistributionDatasource.push({
                  id: counter++,
                  accNo: i.accNo,
                  amount: i.amount,
                  description: i.description,
                  currency: this.billCreateForm.get('currency').value,
                  amtOptCode: iterator.amtOptCode,
                  distOptCode: iterator.distOptCode,
                  categoryId: e.value,
                  categoryName: iterator.catName
                });
              }
              break;
            }
          }
          break;
        }
      }
    }


    addBillCategoryItemToMainDatasource() {
      let totalAmount = 0;
      let data = {};

      for (const iterator of this.accDistributionDatasource) {
        totalAmount += +iterator.amount;
      }

      for (const iterator of this.accDistributionDatasource) {
        data = {
          categoryId: iterator.categoryId,
          categoryName: iterator.categoryName,
          amount: totalAmount,
          distribution: this.accDistributionDatasource,
          description: `${iterator.categoryName} for ${this.billCreateForm.get('payerName').value}`
        };
      }
      this.billItemsDatasource.push(data);
      this.showBillItemsDialog = false;
    }

    addItems()
    {
      if(this.ItemForm.invalid)
      {
        this.toastr.error("Input valid data")
        return
      }
     console.log(this.ItemForm.get("catName").value)
     const data={
        category:this.ItemForm.get("catName").value,
        amount:this.ItemForm.get("amount").value,
        description:this.ItemForm.get("description").value
      }

      this.billItems.push(data)

      this.showBillItemsDialog=false
      this.ItemForm.reset()
    }


  }
