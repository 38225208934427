import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent extends SharedClassComponent implements OnInit {

  title:string = 'Gateways';
  model_name:string = 'gateways';

  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      acronym: new FormControl('', Validators.compose([])),
      description: new FormControl('', Validators.compose([])),
      address: new FormControl('', Validators.compose([])),
      ip: new FormControl('', Validators.compose([Validators.required])),
      port: new FormControl('', Validators.compose([Validators.required])),
      mobile_1: new FormControl('', Validators.compose([])),
      mobile_2: new FormControl('', Validators.compose([])),
      telephone_1: new FormControl('', Validators.compose([])),
      telephone_2: new FormControl('', Validators.compose([])),
      contact_person_1: new FormControl('', Validators.compose([])),
      contact_person_2: new FormControl('', Validators.compose([])),
      created_by: new FormControl('', Validators.compose([])),
      created_at: new FormControl('', Validators.compose([])),
      modified_by: new FormControl('', Validators.compose([])),
      modified_at: new FormControl('', Validators.compose([])),
      status: new FormControl('', Validators.compose([])),
      gateway_id: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
    // Controls the datagrid height and max rows to display
    this.observerCall();

    this.appInfo.setTitle(this.title);
  }

}
