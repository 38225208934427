import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  isBillValid: boolean = true;
  isTISS: boolean = false;
  isTransfer: boolean = false;
  isNullPaymentOption = false;
  isForAllPaymentTypes = false;
  userOperationMode = 202;
  spCreditAccount: any;
  constructor() { }

  public setuserOperationMode(param) {
    this.userOperationMode = param;
  }

  public getUserOperationMode() {
    return this.userOperationMode;
  }

  public setSpCreditAccount(param) {
    this.spCreditAccount = param;
  }

  public getSpCreditAccount() {
    return this.spCreditAccount;
  }

  public setBillValidationStatus(status: boolean) {
  this.isBillValid = status;
  }
  public getBillValidationStatus() {
   return this.isBillValid;
  }

  public setIsTissTransaction(param: boolean) {
  this.isTISS = param;
  }

  public getIsTissTransaction() {
    return this.isTISS;
  }

  public setIsTransferTransaction(param: boolean) {
    this.isTransfer = param;
  }

  public getIsTransferTransaction() {
    return this.isTransfer;
  }

  public setIsNullPaymentOption(param: boolean) {
    this.isNullPaymentOption = param;
  }

  public getIsNullPaymentOption() {
    return this.isNullPaymentOption;
  }

  public setIsForAllPaymentTypes(param: boolean) {
    this.isForAllPaymentTypes = param;
  }

  public getIsForAllPaymentTypes() {
    return this.isForAllPaymentTypes;
  }
}
