import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-outgoing-tiss',
  templateUrl: './outgoing-tiss.component.html',
  styleUrls: ['./outgoing-tiss.component.scss']
})
export class OutgoingTissComponent extends SharedClassComponent implements OnInit {

  title = "OutGoing TISS Report";
  tab_paneldata: any = [
    {
      ID: 1,
      icon: 'fa fa-arrow-circle-o-right',
      name: 'OutGoing TISS Report',
    },
    {
      ID: 2,
      icon: 'fa fa-arrow-circle-o-right',
      name: 'OutGoing TISS Response Report',
    }
  ];

  ngOnInit() {
    // this.rtgsReports('outgoing-tiss');
    // this.rtgsReports('outgoing-tiss-response');
    this.rtgsReportsOutRes();
    this.rtgsReportsOut();
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }

  rtgsReportsOutRes(){
    const endpoint = 'outgoing-tiss-response';
    this.spinner.show();
    this.utilities.rtgsPostServiceCall(endpoint).subscribe(res => {
      const responseData = res.json();
        this.rtgsOutGoingRespDataSet = responseData.data;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong while process the request.');
      console.log(err);
    });
  }

  rtgsReportsOut(){
    const endpoint = 'outgoing-tiss';
    this.spinner.show();
    this.utilities.rtgsPostServiceCall(endpoint).subscribe(res => {
      const responseData = res.json();
        this.rtgsOutGoingDataSet = responseData.data;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong while process the request.');
      console.log(err);
    });
  }

  savePDF(e) {
    const doc = new jsPDF({
      orientation: "landscape"
    });
    const splitTitle = doc.splitTextToSize(e.message, 200);
    console.log(splitTitle);
    doc.text(splitTitle, 5, 10);
    doc.save(`${e.fileName}`.replace('.txt','')+'.pdf');
  }


  onPrintBillreceipt(e) {
    let popUpWindow;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
   // popupWinindow.document.open();
    popUpWindow.document.write(
     `<html>
     <head>
     <link rel="stylesheet" type="text/css" href="style.css" />
     <style>
     @media print {
      @page {
        size: A4;
        margin: 0mm;
      }

      html, body {
        width: 1024px;
      }

      body {
        margin: 0 auto;
        line-height: 1em;
        word-spacing:2px;
        letter-spacing:0.2px;
        font: 20px "Times New Roman", Times, serif;
        color:black;
        width: 100%;
        float: none;
        padding: 2rem;
      }


      /* avoid page-breaks inside a listingContainer*/
      .listingContainer{
        page-break-inside: avoid;
      }
      h1 {
          font: 28px "Times New Roman", Times, serif;
        }

        h2 {
          font: 24px "Times New Roman", Times, serif;
        }

        h3 {
          font: 20px "Times New Roman", Times, serif;
        }

        / Improve colour contrast of links /
        a:link, a:visited {
          color: #781351
        }

        / URL /
        a:link, a:visited {
          background: transparent;
          color:#333;
          text-decoration:none;
        }

        a[href]:after {
          content: "" !important;
        }
        a[href^="http://"] {
          color:#000;
        }
      }
     </style>
     </head>
     <body onload="window.print()"> ${e.message} </html>`
     );
    popUpWindow.document.close();
    this.previewBillsWin = false;
  }


  savePDFResp(e) {
    const doc = new jsPDF({
      orientation: "landscape"
    });
    let splitTitle = '';
    let message = '';
    splitTitle = doc.splitTextToSize(e.message, 180);
    message = `${e.rawResponse}`;
    doc.text(message, 5, 10);
    doc.save(`${e.fileName}`.replace('.txt','')+'.pdf');
  }

  onPrintBillreceiptResp(e) {
    let popUpWindow;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
   // popupWinindow.document.open();
    popUpWindow.document.write(
     `<html>
     <head>
     <link rel="stylesheet" type="text/css" href="style.css" />
     <style>
     @media print {
      @page {
        size: A4;
        margin: 0mm;
      }

      html, body {
        width: 1024px;
      }

      body {
        margin: 0 auto;
        line-height: 1em;
        word-spacing:2px;
        letter-spacing:0.2px;
        font: 20px "Times New Roman", Times, serif;
        color:black;
        width: 100%;
        float: none;
        padding: 2rem;

      }


      /* avoid page-breaks inside a listingContainer*/
      .listingContainer{
        page-break-inside: avoid;
      }
      h1 {
          font: 28px "Times New Roman", Times, serif;
        }

        h2 {
          font: 24px "Times New Roman", Times, serif;
        }

        h3 {
          font: 20px "Times New Roman", Times, serif;
        }

        / Improve colour contrast of links /
        a:link, a:visited {
          color: #781351
        }

        / URL /
        a:link, a:visited {
          background: transparent;
          color:#333;
          text-decoration:none;
        }

        a[href]:after {
          content: "" !important;
        }
        a[href^="http://"] {
          color:#000;
        }
      }
     </style>
     </head>
     <body onload="window.print()"><div>${e.message}</div></br>
      <pre lang="xml"><code>${e.rawResponse}</code></pre>
      </html>`
     );
    popUpWindow.document.close();
    this.previewBillsWin = false;
  }


  onRtgsToolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }

}
