import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-service-provider-accounts',
  templateUrl: './service-provider-accounts.component.html',
  styleUrls: ['./service-provider-accounts.component.scss']
})
export class ServiceProviderAccountsComponent extends SharedClassComponent implements OnInit {

  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = 'Service Provider Accounts';
  model_name = 'sp/accounts'
  currencyDts = [
    {
      id: 'TZS',
      text: 'TZS'
    },
    {
      id: 'USD',
      text: 'USD'
    },
  ];

  ngOnInit() {

    this.appInfo.setTitle(this.title);

    this.paramwinfrm =  new FormGroup({
      requestType: new FormControl('SP_ACC_CREATE', Validators.compose([])),
      spId: new FormControl(null, Validators.compose([])),
      accId: new FormControl(null, Validators.compose([])),
      accNo: new FormControl(null, Validators.compose([Validators.required])),
      accName: new FormControl(null, Validators.compose([Validators.required])),
      currency: new FormControl(null, Validators.compose([Validators.required])),
      accStatus: new FormControl(null, Validators.compose([])),
    });
    this.getServiceProviderAccounts();
    this.observerCall();
    this.getServiceProviders();
  }


  refreshDataGrid() {
    this.getServiceProviderAccounts();
    this.getServiceProviders();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New SP Account',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewParameter.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
        onClick: this.refreshDataGrid.bind(this)
      }
    });
  }

}
