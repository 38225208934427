import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-audit-trail-report',
  templateUrl: './audit-trail-report.component.html',
  styleUrls: ['./audit-trail-report.component.scss']
})
export class AuditTrailReportComponent extends SharedClassComponent implements OnInit {

  title: string = 'Revenue Transactions';
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];

  ngOnInit() {
        //  this.spinner.show();
        this.toastr.info('Data loading in progress', 'Please wait');
        // sets the current page title in index.html title tag
        this.appInfo.setTitle(this.title);

        this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
        this.paramwinfrm = new FormGroup({
          startDate: new FormControl(this.today, Validators.compose([Validators.required])),
          endDate: new FormControl(this.today, Validators.compose([Validators.required]))
        });

        const dd = String(this.today.getDate()).padStart(2, '0');
        const mm = String(this.today.getMonth() + 1).padStart(2, '0'); // January is 0
        const yyyy = this.today.getFullYear();

        //  this.currentDate = dd + '-' + mm + '-' + yyyy;
        this.currentDate = yyyy + '-' + mm + '-' + dd;
        this.getBillRange();
        // Controls the datagrid height and max rows to display
        this.observerCall();
        this.currentUser = this.authService.getUserDetails().username;
  }

  getBillRange() {
    this.spinner.show();
    const startDatestring = this.today.getFullYear() + "-"+  ("0"+(this.today.getMonth()+1)).slice(-2) + "-" +("0" + this.today.getDate()).slice(-2);
    const dt = new Date(startDatestring).setDate(new Date(startDatestring).getDate() + 1);
    const dd = String(new Date(dt).getDate()).padStart(2, '0');
     const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0'); // January is 0
     const yyyy = new Date(dt).getFullYear();
     const endDatestring = yyyy + '-' + mm + '-' + dd;
    const data = {
      requestType: 'REPORT_AUDIT_TRAIL',
      startDate: startDatestring,
      endDate: endDatestring
    };
    this.utilities.postServiceCall(data).subscribe(
        response => {
          const res = response.json();
          this.datasource = res.data;
          this.spinner.hide();
          this.toastr.info('Data has finished loading', 'Process Completed');
        },
        error => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred while processing the request',
            'Request Failed'
          );
        }
      );
  }

  getClearedBillsByDateRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and end Date is required.");
      return;
    }
    this.spinner.show();
    const startDatestring = this.paramwinfrm.get('startDate').value.getFullYear() + "-"+  ("0"+(this.paramwinfrm.get('startDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.paramwinfrm.get('startDate').value.getDate()).slice(-2);

    const dt = new Date(this.paramwinfrm.get('endDate').value).setDate(new Date(this.paramwinfrm.get('endDate').value).getDate() + 1);

    const dd = String(new Date(dt).getDate()).padStart(2, '0');
    const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0'); // January is 0
    const yyyy = new Date(dt).getFullYear();
    const endDatestring = yyyy + '-' + mm + '-' + dd;

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }
    const data = {
      requestType: 'REPORT_AUDIT_TRAIL',
      startDate: startDatestring,
      endDate: endDatestring
    };
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        this.datasource = res.data;
        this.spinner.hide();
        this.toastr.info('Data has finished loading', 'Process Completed');
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  getAuditTrailData(e) {
    const data = {
      requestType: 'REPORT_AUDIT_TRAIL_INFO',
      id: e.id
    }

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        // this.datasource = res.data;
        if (res.statusId == 2000) {
          this.toastr.success('Your download will start shortly.');
          const oldData = res.data.oldData;
          const modifiedData = res.data.modifiedData;
          const oldDataFilename = 'audit_trail_id_'+res.data.id+'_date_'+res.data.timestamp+'_old_data.txt';
          const newDataFilename = 'audit_trail_id_'+res.data.id+'_date_'+res.data.timestamp+'_new_data.txt';
          this.spinner.hide();
          this.downloadMessage(oldDataFilename, oldData, 'plain/text');
          setTimeout(() => {
            this.downloadMessage(newDataFilename, modifiedData, 'plain/text');
          }, 2000);
        } else {
          this.toastr.error(res.statusMessage);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }

}
