import {
  Component,
  NgModule,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService, AppInfoService } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { UtilitiesService } from '../../services/utilities.service';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxDateBoxModule,
  DxTextBoxModule,
  DxPopupModule
} from 'devextreme-angular';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  appName: string;
  roleName: string = '';
  endpoint: string = 'roles/get';
  data = {
    role_id: 0
  };
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;
  model_name: string = 'users/password';
  endpointcall: string = 'change';
  response: any;
  @Input()
  title: string;
  changePasswordFrm: FormGroup;
  changePasswordWin: boolean;
  userMenuItems = [];

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    private utilityService: UtilitiesService
  ) {
    this.appName = this.appInfo.title;
    this.changePasswordFrm = new FormGroup({
      old_password: new FormControl('',Validators.compose([Validators.required])),
      new_password: new FormControl('',Validators.compose([Validators.required])),
      confirm_password: new FormControl('',Validators.compose([Validators.required]))
    });

    const user = this.authService.getUserDetails();

    this.roleName = user.firstName +' ' +user.lastName+' | '+ user.spAlias;
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
  redirectToHome = () => {
    if (this.router.url !== 'home') {this.router.navigate(['/home']); }
  }
  funcUserLogOut() {
    this.authService.logOut();
  }
  funcChangeUserPassword() {
    this.changePasswordWin = true;
  }
  funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
  }
  OnPasswordReset() {
    if (this.changePasswordFrm.invalid && !this.changePasswordFrm.touched) {
      this.toastr.error('Fill in all the form details', 'Response');
      return;
    }

    if (!this.changePasswordFrm.get('new_password').valid) {
      this.toastr.error('Password must be 6 or more characters, with a capital letter, small letter, a number and a special character');
      return;
    }
    // check the details
    const new_password = this.changePasswordFrm.get('new_password').value;
    const confirm_password = this.changePasswordFrm.get('confirm_password').value;

    if (new_password != confirm_password) {
      this.toastr.error('Password didn\'t match', 'Response');
      this.changePasswordFrm.get('new_password').setValue(''),
      this.changePasswordFrm.get('confirm_password').setValue('');
      return;
    }
    this.authService
      .changeUserpassword(
        this.changePasswordFrm.value,
        this.model_name + '/' + this.endpointcall
      )
      .subscribe(
        response => {
          this.response = response.json();

          if (this.response.statusId == 2000) {
            this.changePasswordWin = false;
            this.toastr.success('Password Changed Successfully!', 'Response');
            this.changePasswordFrm.reset();
            this.changePasswordFrm.get('new_password').setValue(''),
            this.changePasswordFrm.get('confirm_password').setValue('');
            this.changePasswordFrm.get('old_password').setValue('');
          } else {
            this.toastr.error(this.response.statusMessage, 'Alert');
            this.changePasswordFrm.reset();
            this.changePasswordFrm.get('new_password').setValue(''),
            this.changePasswordFrm.get('confirm_password').setValue('');
            this.changePasswordFrm.get('old_password').setValue('');
          }
          this.spinner.hide();
        },
        error => {
          this.toastr.error(
            error,
            'Response'
          );
        }
      );
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxDataGridModule,
    DxFormModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,

    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
